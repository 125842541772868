import React, { useState, useEffect, useRef } from 'react';
import OptionSelector from '../option-selector/optionSelect';

function GroupChat({
  localPeerId,
  groupMessages,
  sendGroupMessage,
  isRoomJoined,
  teacherView,
}) {
  const [message, setMessage] = useState('');
  const messagesEndRef = useRef(null); // Reference to the end of the message list

  const handleInputChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSendMessage = () => {
    if (message) {
      sendGroupMessage(message);
      setMessage('');
    }
  };

  const handleOptionSelect = (option) => {
    sendGroupMessage(option);
  };

  // Auto scroll to the bottom when new messages are added
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [groupMessages]); // Trigger when groupMessages update

  return (
    <>
      <div className="h-full overflow-auto px-4 mt-4">
        {groupMessages.map((ele, i) => (
          <React.Fragment key={i}>
            <div
              className={`mb-4 ${ele.id === localPeerId ? 'text-right' : 'text-left'}`}
            >
              <div className="text-sm text-gray-500 mb-1">{ele.sender}</div>
              <div
                className={`${
                  ele.id === localPeerId
                    ? 'bg-blue-500 text-white'
                    : 'bg-gray-200 text-gray-900'
                } p-3 rounded-t-lg rounded-bl-lg inline-block max-w-xs`}
              >
                {ele.msg}
              </div>
            </div>
          </React.Fragment>
        ))}
        {/* Empty div for scrolling to the bottom */}
        <div ref={messagesEndRef} />
      </div>
      {isRoomJoined && (
        <>
          {!teacherView && (
            <OptionSelector onOptionSelect={handleOptionSelect} />
          )}
          <div className="flex px-2 py-1 mx-4 my-2 border border-gray-300 rounded-lg">
            <input
              type="text"
              placeholder="Type a message"
              className="w-full py-2 rounded-l-full px-2 focus:outline-none focus:border-transparent"
              onChange={handleInputChange}
              value={message}
            />
            <button
              className="px-2 mt-2 cursor-pointer"
              onClick={handleSendMessage}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="31"
                viewBox="0 0 28 31"
                fill="none"
              >
                <path
                  d="M27.75 15.1554L0 30.3109L8.25 15.1554L0 0L27.75 15.1554Z"
                  fill="#606060"
                />
              </svg>
            </button>
          </div>
        </>
      )}
    </>
  );
}

export default GroupChat;
