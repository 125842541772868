import React from 'react';

const OptionSelector = ({ onOptionSelect }) => {
    const handleOptionClick = (option) => {
      onOptionSelect(option);
    };  

  return (
    <div className="flex flex-col items-start p-2 " >
        <div className="text-md font-medium mb-2 ml-2">Please select an option:</div>
        <div className="flex items-center justify-center ml-2">
        <div className="flex space-x-1">
            <button
            className="bg-green-500 text-white p-2 rounded-full hover:bg-green-600"
            onClick={() => handleOptionClick('Yes')}
            >
                Yes
            </button>
            <button
            className="bg-red-500 text-white p-2 rounded-full hover:bg-red-600"
            onClick={() => handleOptionClick('No')}
            >
                No
            </button>
        </div>

        <div className="w-[2px] h-10 bg-black mx-2 "></div> {/* Vertical divider */}

        <div className="flex space-x-1">
            <button
            className="bg-blue-500 text-white py-2 px-3 rounded-lg shadow-md hover:bg-blue-600"
            onClick={() => handleOptionClick('A')}
            >
            A
            </button>
            <button
            className="bg-blue-500 text-white py-2 px-3 rounded-lg shadow-md hover:bg-blue-600"
            onClick={() => handleOptionClick('B')}
            >
            B
            </button>
            <button
            className="bg-blue-500 text-white py-2 px-3 rounded-lg shadow-md hover:bg-blue-600"
            onClick={() => handleOptionClick('C')}
            >
            C
            </button>
            <button
            className="bg-blue-500 text-white py-2 px-3 rounded-lg shadow-md hover:bg-blue-600"
            onClick={() => handleOptionClick('D')}
            >
            D
            </button>
        </div>
        
        </div>
    </div>
  );
};

export default OptionSelector;
