import React, { useState } from 'react';

function ChatUsers({ chatUsers, setSelectedUserId }) {
  const [selectedUsers, setSelectedUsers] = useState([]);

  const handleCheckboxChange = (socketId) => {
    setSelectedUsers((prev) =>
      prev.includes(socketId)
        ? prev.filter((id) => id !== socketId)
        : [...prev, socketId]
    );
  };

  return (
    <div className="h-full overflow-auto px-4">
      {chatUsers.map((ele, i) => (
        <div key={i} className="flex items-center py-2">
          <div
            className="flex-grow flex items-center"
            role="button"
            onClick={() => {
              setSelectedUserId(ele?.socketId);
            }}
          >
            <img src="/images/navbar/profile.png" alt="" />
            <p className="ml-8">{ele.name}</p>
            {ele?.didRaiseHand && <span className="ml-auto">🤚</span>}
          </div>
          {/* <div className="checkbox-container">
            <input
              type="checkbox"
              className="ml-4"
              checked={selectedUsers.includes(ele.socketId)}
              onChange={(e) => {
                e.stopPropagation(); // Prevent the click from affecting parent div
                handleCheckboxChange(ele.socketId);
              }}
            />
          </div> */}
        </div>
      ))}
    </div>
  );
}

export default ChatUsers;
