import React, { useState } from 'react';

const VideoSlider = ({streams, onVideoSelect, studentsArray, onMouseEnter, onMouseLeave}) => {
  const [startIndex, setStartIndex] = useState(0);
  const itemsPerPage = 4;
  
  // const videos = [
  //   { name: 'John Doe', videoUrl: '/video1.mp4' },
  //   { name: 'Jose', videoUrl: '/video2.mp4' },
  //   { name: 'Sean Parker', videoUrl: '/video1.mp4' },
  //   { name: 'Justin Timberlake', videoUrl: '/video2.mp4' },
  //   { name: 'Manjit', videoUrl: '/video1.mp4' },
  //   { name: 'Nikhil', videoUrl: '/video2.mp4' },
  //   { name: 'Gaurav', videoUrl: '/video1.mp4' },
  //   { name: 'AswaniVY', videoUrl: '/video2.mp4' },
  //   { name: 'Varun', videoUrl: '/video1.mp4' },
  //   { name: 'Salman', videoUrl: '/video2.mp4' },
  //   { name: 'Sara', videoUrl: '/video1.mp4' },
  //   { name: 'Jackie', videoUrl: '/video2.mp4' },
  //   { name: 'Samily', videoUrl: '/video1.mp4' },
  //   { name: 'Alan', videoUrl: '/video2.mp4' },
  //   { name: 'George', videoUrl: '/video1.mp4' },
  //   { name: 'Justin Timberlake', videoUrl: '/video2.mp4' },
  //   { name: 'Brian', videoUrl: '/video1.mp4' },
  //   { name: 'Tj', videoUrl: '/video2.mp4' },
  //   { name: 'Bella', videoUrl: '/video1.mp4' },
  //   { name: 'Akon', videoUrl: '/video2.mp4' },
  //   { name: 'Travis Scott', videoUrl: '/video1.mp4' },
  //   { name: 'Tupac', videoUrl: '/video2.mp4' },
  //   { name: 'Jenny', videoUrl: '/video1.mp4' },
  //   { name: 'Kim', videoUrl: '/video2.mp4' },
  //   { name: 'Arohi', videoUrl: '/video1.mp4' },
  //   { name: 'Nilam', videoUrl: '/video2.mp4' },
  //   { name: 'Ratan', videoUrl: '/video1.mp4' },
  //   { name: 'Simran', videoUrl: '/video2.mp4' },
  //   { name: 'Rahul', videoUrl: '/video1.mp4' },
  //   { name: 'Kimberly', videoUrl: '/video2.mp4' },
  //   { name: 'Time Lee', videoUrl: '/video1.mp4' },
  // ];

  const handlePrev = () => {
    setStartIndex((prevIndex) => Math.max(0, prevIndex - itemsPerPage));
  };

  const handleNext = () => {
    setStartIndex((prevIndex) => Math.min(streams.length - itemsPerPage, prevIndex + itemsPerPage));
  };
  // const handleSelectvid = (videoUrl) => {
  //   setSelectedVideo(videoUrl);
  // };
  return (
    <div className="container mx-auto px-2 mt-2 ">
      
      <div className="flex justify-start mb-4">
        <button onClick={handlePrev} className="mr-1 p-1 rounded-full hover:bg-gray-300 cursor-pointer" disabled={startIndex === 0}>
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none" className='h-6 w-6' style={{ color: startIndex === 0 ? 'gray' : '#683CB4' }}>
          
            <rect x="0.5" y="0.5" width="29" height="29" rx="14.5" stroke={startIndex === 0 ? 'gray' : '#683CB4'}/>
            <path d="M18.75 22.5L11.25 15L18.75 7.5" stroke={startIndex === 0 ? 'gray' : '#683CB4'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </button>
        <button onClick={handleNext} className="p-1 rounded-full hover:bg-gray-300 cursor-pointer" disabled={startIndex + itemsPerPage >= streams.length}>
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none" className='h-6 w-6 'style={{ color: startIndex + itemsPerPage >= streams.length ? 'gray' : '#683CB4' }}>
            <rect x="29.5" y="29.5" width="29" height="29" rx="14.5" transform="rotate(-180 29.5 29.5)" stroke={startIndex + itemsPerPage >= streams.length ? 'gray' : '#683CB4'}/>
            <path d="M11.25 7.5L18.75 15L11.25 22.5" stroke={startIndex + itemsPerPage >= streams.length ? 'gray' : '#683CB4'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </button>
      </div>
      <div className="relative overflow-hidden ">
        <div className="flex transition-transform duration-500" style={{ transform: `translateX(-${(startIndex / itemsPerPage) * 100}%)` }}>
        {streams.map((streamData, index) => (
            <div key={streamData.id} className="flex-none w-[18%] p-2" 
            id={`studentsVideoContainer__${streamData.id}`}
            onClick={() => onVideoSelect(streamData.stream)} 
            
            >
              <div className="w-full mb-2 rounded-md shadow-lg overflow-hidden cursor-pointer"
              onClick={() => onVideoSelect(streamData)}>
                <video
                  autoPlay
                  playsInline
                  muted
                  className="w-full h-40 object-cover"
                  ref={el => {
                    if (el) el.srcObject = streamData.stream;
                  }}
                  onMouseEnter={() => onMouseEnter(streamData)}
              onMouseLeave={onMouseLeave}
                />
              </div>
              {/* <p className="text-center">Student {index + 1}</p> */}
              <p className="text-center">
                {studentsArray.find(
                  (student) => student.socketId === streamData.id
                )?.name || 'Unknown Student'}
              </p>
            </div>
          ))}
        </div>
      </div>
      <div className="flex justify-center mt-4">
        <div className="flex space-x-2">
          {Array.from({ length: Math.ceil(streams.length / itemsPerPage) }).map((_, index) => (
            <div
              key={index}
              onClick={() => setStartIndex(index * itemsPerPage)}
              className={`h-3 w-3 rounded-full mb-3 cursor-pointer ${index === Math.floor(startIndex / itemsPerPage) ? 'bg-purple-600' : ' border-purple-600 border-2'}`}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default VideoSlider;
