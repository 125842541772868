import axiosInstance from '../api/axiosInstance';

export const signupAction = async (
  email,
  firstName,
  lastName,
  password,
  role,
  grade,
) => {
  try {
    const response = await axiosInstance.post('/auth/signup', {
      email,
      firstName,
      lastName,
      password,
      role,
      grade,
    });

    if (response.data && response.data.data && response.data.data.token) {
      localStorage.setItem('token', response.data.data.token);
      localStorage.setItem('id', response.data.data.user.id);
      localStorage.setItem('role', response.data.data.user.role);
      localStorage.setItem('refreshToken', response.data.data.refreshToken);
    } else {
      console.log('Response structure:', response.data);
    }

    return response.data;
  } catch (error) {
    console.error('Signup error:', error);
    throw error;
  }
};

export const loginAction = async (email, password) => {
  try {
    const response = await axiosInstance.post('/auth/login', {
      email,
      password,
    });

    if (response.data && response.data.token) {
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('id', response.data.user.id);
      localStorage.setItem('role', response.data.user.role);
      localStorage.setItem('refreshToken', response.data.refreshToken);
    } else {
      console.log('Login Response structure:', response.data);
    }

    return response.data;
  } catch (error) {
    console.error('Login error:', error);
    throw error;
  }
};

export const refreshToken = async () => {
  try {
    const refreshToken = localStorage.getItem('refreshToken');
    if (!refreshToken) {
      throw new Error('No refresh token available');
    }

    const response = await axiosInstance.post('/auth/refresh-token', {
      token: refreshToken,
    });

    if (response.data && response.data.token) {
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('refreshToken', response.data.refreshToken);
      return response.data.token;
    } else {
      throw new Error('Failed to refresh token');
    }
  } catch (error) {
    console.log('Refresh token error:', error);
    throw error;
  }
};

export const getIceServers = async () => {
  try {
    const response = await axiosInstance.get('/auth/ice-servers');
    console.log('Response in getIceServers',response);
    return {
      iceServers: response.data.iceServers
    };
  } catch (error) {
    console.error('Error fetching ICE servers:', error);
    throw error;
  }
};
