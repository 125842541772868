import React, { useEffect, useState } from 'react';
import LandingNav from '../LandingPage/Landingnav/landingnav';
import Landingfooter from '../LandingPage/LandingFooter/landingfooter';
import TeacherHome from './teacherhome';
import Studenthome from './studenthome';
import LandingPage from '../LandingPage/landingpage';

const MainHome = () => {
  const [loading, setLoading] = useState(true);
  const role = localStorage.getItem('role');
  const token = localStorage.getItem('token');
  useEffect(() => {
    if (!token) {
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [token]);

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <LandingNav />
      {!token ? (
        <LandingPage />
      ) : (
        <>
          {role === 'teacher' ? <TeacherHome /> : <Studenthome />}
        </>
      )}
      <Landingfooter />
    </>
  );
};

export default MainHome;
