import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { getProfileAction } from '../../../action/profileAction';

const LandingNav = () => {
  
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [profileData, setProfileData] = useState(null);

  const handleLoginClick = () => {
    navigate('/login');
  };
  const handlesignupClick = () => {
    navigate('/signup');
  };

  const isLoggedIn = !!localStorage.getItem('token');
  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('id');
    localStorage.removeItem('role');
    localStorage.removeItem('refreshToken');
    navigate('/');
    window.location.reload();
  };
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const handleviewProfile = () => {
    navigate('/profile');
  };
  const handlemainHome = () => {
    navigate('/');
  };
  useEffect(() => {
    const fetchProfileData = async () => {
      if (isLoggedIn) {
        try {
          const data = await getProfileAction();
          setProfileData(data);
        } catch (error) {
          console.error(`Error fetching profile data: ${error.response.data.error}`);
          toast.error(`Error fetching profile data: ${error.response.data.error}`, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }
    };

    fetchProfileData();
  }, [isLoggedIn, isDropdownOpen]);
  return (
    <>
    <ToastContainer />
      <nav className="bg-white  fixed w-full z-20 top-0 start-0 border-b border-gray-200 dark:border-gray-600">
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
          <a
            onClick={isLoggedIn ? handlemainHome : handleLogout}
            className="flex items-center space-x-3 ml-5 rtl:space-x-reverse cursor-pointer"
          >
            <span className="self-center text-4xl text-cyan-400 font-outline-2 font-extrabold leading-6">
              EDFX
            </span>
          </a>
          <div className="flex -ml-4 md:order-2 space-x-5 md:space-x-0 rtl:space-x-reverse">
            {!isLoggedIn ? (
              <>
                <button
                  type="button"
                  onClick={handleLoginClick}
                  className="text-black -ml-4 mr-2 px-4 py-1 bg-white border border-1 border-black rounded-md shadow-md"
                >
                  Login
                </button>
                <button
                  type="button"
                  onClick={handlesignupClick}
                  className="text-white px-4 py-1 bg-teal-700 rounded-md shadow-md"
                >
                  Signup
                </button>
              </>
            ) : (
              <div className="relative">
                <img
                  id="avatarButton"
                  type="button"
                  onClick={toggleDropdown}
                  className="w-10 h-10 rounded-full cursor-pointer"
                  src="/images/navbar/profile.png"
                  alt="Profile"
                />
                {isDropdownOpen && (
                  <div className="absolute mt-6 right-0  w-44  bg-gray-400 rounded-lg shadow-xl">
                    {profileData && (
                      <div className="px-4 py-3 text-sm text-black font-medium">
                        <div>{`${profileData.firstName} ${profileData.lastName}`}</div>
                        <div className="font-medium truncate">
                          {profileData.email}
                        </div>
                      </div>
                    )}
                    <ul className="py-2 text-sm text-black font-medium ">
                      <li>
                        <a
                          onClick={handleviewProfile}
                          className="block px-4 py-2 hover:bg-gray-100 cursor-pointer"
                        >
                          View Profile
                        </a>
                      </li>
                    </ul>
                    <div className="py-1">
                      <a
                        onClick={handleLogout}
                        className="block px-4 py-2 text-sm text-black font-medium hover:bg-gray-100 cursor-pointer"
                      >
                        Logout
                      </a>
                    </div>
                  </div>
                )}
              </div>
            )}

            <button
              data-collapse-toggle="navbar-sticky"
              type="button"
              className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
              aria-controls="navbar-sticky"
              aria-expanded="false"
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 17 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 1h15M1 7h15M1 13h15"
                />
              </svg>
            </button>
          </div>
          <div
            className="items-center justify-between hidden w-full md:flex md:w-auto md:order-1"
            id="navbar-sticky"
          >
            <ul className="flex flex-col p-4 md:p-0 mt-4 font-medium border border-gray-100 rounded-lg bg-gray-50 md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-white ">
              <li>
                <a
                  href="#"
                  className="text-white px-4 py-1 bg-violet-900 rounded-md shadow-md"
                  aria-current="page"
                >
                  Curriculum
                </a>
              </li>
              <li>
                <a
                  href="#"
                  className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500  "
                >
                  Lessons
                </a>
              </li>
              <li>
                <a
                  href="#"
                  className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500  "
                >
                  How to teach
                </a>
              </li>
              <li>
                <a
                  href="#"
                  className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500  "
                >
                  Subscribe
                </a>
              </li>
              <li>
                <a
                  href="#"
                  className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500  "
                >
                  Contact
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default LandingNav;
