import axios from 'axios';
import { refreshToken } from '../action/authAction';

const axiosInstance = axios.create({

   baseURL: 'https://16.171.145.2:3001',
 // baseURL: 'http://localhost:3001',

  timeout: 10000,
});

//  Request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    // Modify request configuration before sending
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    // Handle request error
    return Promise.reject(error);
  },
);

// Response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    
    if (error.response && error.response.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const newToken = await refreshToken();
        localStorage.setItem('token', newToken);
        // axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
        originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
        return axiosInstance(originalRequest);
      } catch (e) {
        console.error('Token refresh failed:', e);
      }
    }
    return Promise.reject(error);
  },
);

export default axiosInstance;
