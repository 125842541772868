import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from '../shared/navbar/navbar';
import TeacherRoom from '../room/TeacherRoom/TeacherRoom';
import StudentRoom from '../room/StudentRoom/StudentRoom';
import { Constants } from '../../config/Constants';
import { getProfileAction } from '../../action/profileAction';

const Home = ({ role }) => {
  const { room } = useParams();
  const [profileData, setProfileData] = useState(null);
  const [username, setUsername] = useState('');

  const isLoggedIn = !!localStorage.getItem('token');

  useEffect(() => {
    const fetchProfileData = async () => {
      if (isLoggedIn) {
        try {
          const data = await getProfileAction();
          setProfileData(data);
          setUsername(`${data.firstName} ${data.lastName}`);
        } catch (error) {
          console.log(
            `Error fetching profile data ${error.response.data.error}`,
          );

          toast.error(
            `Error fetching profile data .${error.response.data.error}`,
            {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            },
          );
        }
      }
    };

    fetchProfileData();
  }, [isLoggedIn]);

  return (
    <>
      <ToastContainer />
      {/* <Navbar /> */}
      {role === Constants.ROLE.TEACHER ? (
        <TeacherRoom room={room} username={username} />
      ) : (
        <StudentRoom room={room} username={username} />
      )}
    </>
  );
};

export default Home;
