import axiosInstance from '../api/axiosInstance';

export const createRoomAction = async (roomData) => {
  try {
    const response = await axiosInstance.post('/room', roomData);
    return response.data;
  } catch (error) {
    console.error('Error creating room:', error);
    throw error;
  }
};

export const getRoomAction = async () => {
    try {
      const userId = localStorage.getItem('id');
      if (!userId) {
        throw new Error('User ID not found in local storage');
      }
  
      const response = await axiosInstance.get(`/room/${userId}`);
      
      if (response.data) {
        return response.data;
      } else {
        console.log('Room Response structure:', response.data);
        throw new Error('Unexpected response structure');
      }
    } catch (error) {
      console.error('Get Room error:', error);
      throw error;
    }
  };

  
export const getAllRoomAction = async () => {
  try {
    

    const response = await axiosInstance.get(`/room`);
    
    if (response.data) {
      return response.data;
    } else {
      console.log('Room Response structure:', response.data);
      throw new Error('Unexpected response structure');
    }
  } catch (error) {
    console.error('Get Room error:', error);
    throw error;
  }
};

export const deleteRoomAction = async (roomId) => {
  try {
    const response = await axiosInstance.delete(`/room/${roomId}`);

    if (response.data) {
      return response.data;
    } else {
      console.log('Room Response structure:', response.data);
      throw new Error('Unexpected response structure');
    }
  } catch (error) {
    console.error('Delete Room error:', error);
    throw error;
  }
};

export const changeRoomStatus = async (roomId, status) => {
  try {
    console.log(roomId,status)
    const response = await axiosInstance.put(`/room/updateStatus/${roomId}`, { status });

    if (response.data) {
      return response.data;
    } else {
      console.error('Unexpected response structure:', response);
      throw new Error('Unexpected response structure');
    }
  } catch (error) {
    console.error('Change Room Status error:', error);
    throw error;
  }
};
