import React from 'react'

const Landingfooter = () => {
  return (
    <>
    <div className="bg-white-300 pt-44 pb-24">
      <div className="max-w-screen-xl w-full mx-auto px-6 sm:px-8 lg:px-16 grid grid-rows-6 sm:grid-rows-1 grid-flow-row sm:grid-flow-col grid-cols-3 sm:grid-cols-12 gap-4">
        <div className="row-span-2 sm:col-span-4 col-start-1 col-end-4 sm:col-end-5 flex flex-col items-start ">
        <img className="" src="/images/navbar/logo.png" alt="" />
          <p className="mb-4">
            <strong className="font-medium">Lorem Ipsum </strong> is simply dummy text of the <br />
            printing and typesetting industry.
          </p>
          <div className="flex w-full mt-2 mb-8 -mx-2">
            <div className="mx-2  items-center justify-center flex ">
            <img className="h-6 w-6 shadow-md rounded-sm" src="/images/navbar/facebook.png" alt="" />
            </div>
            <div className="mx-2  items-center justify-center flex ">
            <img className="h-6 w-6 shadow-md rounded-sm" src="/images/navbar/twitter.png" alt="" />
            </div>
            <div className="mx-1  items-center justify-center flex ">
            <img className="h-6 w-8 " src="/images/navbar/instagram.png" alt="" />
            </div>
          </div>
          {/* <p className="text-gray-400">©{new Date().getFullYear()} - Legalroom</p> */}
          <p className="text-black-600">© Copyright 2024. All right reserved</p>
        </div>
        <div className=" row-span-2 sm:col-span-2 sm:col-start-7 sm:col-end-9 flex flex-col">
          <p className="text-black-600 mb-4 font-medium text-lg">Home</p>
          <ul className="text-black-500 ">
            <li className="my-2 hover:text-orange-500 cursor-pointer transition-all">
              About Us{" "}
            </li>
            <li className="my-2 hover:text-orange-500 cursor-pointer transition-all">
              Pricing{" "}
            </li>
            <li className="my-2 hover:text-orange-500 cursor-pointer transition-all">
              Contact us{" "}
            </li>
            <li className="my-2 hover:text-orange-500 cursor-pointer transition-all">
              Features{" "}
            </li>
            <li className="my-2 hover:text-orange-500 cursor-pointer transition-all">
              Why Us?{" "}
            </li>
            <li className="my-2 hover:text-orange-500 cursor-pointer transition-all">
              Blogs{" "}
            </li>
          </ul>
        </div>
        <div className="row-span-2 sm:col-span-2 sm:col-start-9 sm:col-end-11 flex flex-col">
          <p className="text-black-600 mb-4 font-medium text-lg">Contact us</p>
          <ul className="text-black-500">
            <li className="my-2 hover:text-orange-500 cursor-pointer transition-all">
              Email Us{" "}
            </li>
            <li className="my-2 hover:text-orange-500 cursor-pointer transition-all">
              FAQ{" "}
            </li>
            <li className="my-2 hover:text-orange-500 cursor-pointer transition-all">
              Contact us{" "}
            </li>
            <li className="my-2 hover:text-orange-500 cursor-pointer transition-all">
              About Us{" "}
            </li>
            <li className="my-2 hover:text-orange-500 cursor-pointer transition-all">
              Privacy Policy{" "}
            </li>
            <li className="my-2 hover:text-orange-500 cursor-pointer transition-all">
              Terms of Service{" "}
            </li>
          </ul>
        </div>
        <div className="row-span-2 sm:col-span-2 sm:col-start-11 sm:col-end-13 flex flex-col">
          <p className="text-black-600 mb-4 font-medium text-lg">Earn Money</p>
          <ul className="text-black-500">
            <li className="my-2 hover:text-orange-500 cursor-pointer transition-all">
              Affiliate{" "}
            </li>
            <li className="my-2 hover:text-orange-500 cursor-pointer transition-all">
              Become Partner{" "}
            </li>
            <li className="my-2 hover:text-orange-500 cursor-pointer transition-all">
              Do Business{" "}
            </li>
            <li className="my-2 hover:text-orange-500 cursor-pointer transition-all">
              Earn with us{" "}
            </li>
            <li className="my-2 hover:text-orange-500 cursor-pointer transition-all">
              Buy Now{" "}
            </li>
            <li className="my-2 hover:text-orange-500 cursor-pointer transition-all">
              Become Partner{" "}
            </li>
          </ul>
        </div>
      </div>
    </div>
    </>
  )
}

export default Landingfooter