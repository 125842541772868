import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { socket } from '../../../socket';
import styles from './teacher-room.module.scss';
import clsx from 'clsx';
import { Constants } from '../../../config/Constants';
import Chat from '../../chat/Chat';
import VideoSlider from '../../screenshare/slide/videoslider';
import { changeRoomStatus } from '../../../action/roomAction';
import {
  MonitorUp,
  PhoneCall,
  PhoneOff,
  ScreenShare,
  ScreenShareOff,
} from 'lucide-react';
import { getIceServers } from '../../../action/authAction';
import LeaveCallModal from '../LeaveRoom/LeaveCallModal';
function TeacherRoom({ room, username }) {
  // const iceServers = {
  //   iceServers: [
  //     {
  //       urls: "stun:global.stun.twilio.com:3478"
  //   },
  //   {
  //       username: "e3703f4d48526603d0bd6863db75773024c1d793efe98f70cc41faaa4ac08d69",
  //       urls: "turn:global.turn.twilio.com:3478?transport=udp",
  //       credential: "8P82CePg10dCICmQ9vBxkZP8b6Ma5nD74a3eV6nxZR0="
  //   }
  //   ],
  // };
  const [iceServers, setIceServers] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const navigate = useNavigate();
  const peerConnectionRef = useRef([]);
  const dataChannelRef = useRef([]);
  const remotePeerIdsRef = useRef([]);
  const mainVideoContainerRef = useRef(null);
  const myVideoContainerRef = useRef(null);
  const localPeerIdRef = useRef(null);
  const screenShareStreamRef = useRef(null);
  const [isRoomJoined, setIsRoomJoined] = useState(false);
  const [isScreenSharing, setIsScreenSharing] = useState(false);
  const [isStudentScreenSharing, setIsStudentScreenSharing] = useState(false);
  const [studentsArray, setStudentsArray] = useState([]);
  const [groupMessages, setGroupMessages] = useState([]);
  const [directMessages, setDirectMessages] = useState({});
  const [studentStreams, setStudentStreams] = useState([]);
  const [receivedMessage, setReceivedMessage] = useState(false);
  const selectedStreamRef = useRef(null);
  const [confirmModal, setConfirmModal] = useState(false);

  const [isHovering, setIsHovering] = useState(false);
  const [hoveredStream, setHoveredStream] = useState(null);

  const handleMouseEnter = (streamData) => {
    setIsHovering(true);
    setHoveredStream(streamData);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
    setHoveredStream(null);
  };

  useEffect(() => {
    const fetchIceServers = async () => {
      try {
        // Call the API function to fetch ICE servers
        const { iceServers } = await getIceServers();

        console.log('Fetched ICE servers:', iceServers);
        setIceServers(iceServers);
        setIsLoaded(true);
      } catch (error) {
        console.error('Failed to fetch ICE servers:', error);
      }
    };

    fetchIceServers();
  }, []);

  useEffect(() => {
    const handlePopState = (event) => {
      event.preventDefault();
      if (isRoomJoined) {
        setConfirmModal(true);
        window.history.pushState(null, null, window.location.pathname);
      } else {
        navigate('/');
      }
    };

    const handleBeforeUnload = (event) => {
      leaveCall();
    };

    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', handlePopState);
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('popstate', handlePopState);
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isRoomJoined, navigate]);

  const handleConfirmLeave = () => {
    leaveCall();
    setConfirmModal(false);
  };

  const handleCloseModal = () => {
    setConfirmModal(false);
  };

  const handleChangeRoomStatus = async (room, status) => {
    try {
      await changeRoomStatus(room, status);
    } catch (error) {
      console.error('Error updating room status:', error.response?.data?.error);
    }
  };

  const handleMessageRoom = async (obj) => {
    if (obj.type === 'start_call') {
      const remotePeerId = obj.data.senderId;
      remotePeerIdsRef.current.push(remotePeerId);
      peerConnectionRef.current[remotePeerId] = new RTCPeerConnection(
        {iceServers:iceServers},
      );
      dataChannelRef.current[remotePeerId] =
        peerConnectionRef.current[remotePeerId].createDataChannel(
          'myDataChannel',
        );
      dataChannelRef.current[remotePeerId].onopen = function () {
        console.log('Data channel opened');
      };
      dataChannelRef.current[remotePeerId].onmessage = function (event) {
        const newMessage = JSON.parse(event.data);
        if (newMessage.type === 'group') {
          setGroupMessages((prev) => [...prev, newMessage]);
        } else if (newMessage.type === 'direct') {
          // setDirectMessages((prev) => ({
          //   ...prev,
          //   [newMessage.id]: prev[newMessage.id]
          //     ? [...prev[newMessage.id], newMessage]
          //     : [newMessage],
          // }));
          setDirectMessages((prev) => ({
            ...prev,
            [newMessage.sender]: [
              ...(prev[newMessage.sender] || []),
              newMessage,
            ],
          }));
          toast.info(`New Message from ${newMessage.recieverName}`, {
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
          });
        }
        console.log('Received message:', newMessage);
      };
      dataChannelRef.current[remotePeerId].onclose = function () {
        console.log('Data channel closed');
        let updatedStudentsArray = studentsArray.filter((e) => {
          return e.socketId !== remotePeerId;
        });

        // setStudentsArray(updatedStudentsArray);
      };
      // addLocalTracks(peerConnectionRef.current[remotePeerId]);
      peerConnectionRef.current[remotePeerId].addEventListener(
        'connectionstatechange',
        (event) => handleConnectionStateChange(event, remotePeerId),
      );
      peerConnectionRef.current[remotePeerId].addEventListener(
        'icecandidate',
        (event) => handleICECandidateEvent(event, remotePeerId),
      );
      peerConnectionRef.current[remotePeerId].addEventListener(
        'icecandidate',
        (event) => handleICEGatheringStateChange(event, remotePeerId),
      );
      peerConnectionRef.current[remotePeerId].addEventListener(
        'track',
        (event) => handleTrackEvent(event, remotePeerId),
      );
      peerConnectionRef.current[remotePeerId].addEventListener(
        'negotiationneeded',
        (event) => handleNegotiationneeded(event, remotePeerId),
      );
      socket.emit('messageDirect', {
        type: 'teacher-joined-the-room',
        data: {
          id: 1,
          name: 'teacher',
          socketId: localPeerIdRef.current,
          senderId: localPeerIdRef.current,
          receiverId: remotePeerId,
        },
      });
      addLocalTracks(false, remotePeerId);
    } else if (obj.type === 'student-left-the-room') {
      const { roomId, name, senderId, socketId } = obj.data;
      // socket.to(roomId).emit('messageRoom', {
      //   type: 'student-left-the-room',
      //   data: {
      //     senderId,
      //   },
      // });
      if (name) {
        toast.info(`${name} left the call`, {
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        });
      }
      if (typeof peerConnectionRef.current[senderId] !== typeof undefined) {
        peerConnectionRef.current[senderId].close();
        remotePeerIdsRef.current = remotePeerIdsRef.current.filter(
          (val) => val !== senderId,
        );
        console.log();
      }

      setStudentStreams((prevStreams) => {
        const updatedStreams = prevStreams.filter(
          (stream) => stream.id !== senderId,
        );
        return updatedStreams;
      });

      setStudentsArray((prev) => {
        const filteredArray = prev.filter((e) => {
          return e.socketId !== socketId;
        });
        return filteredArray;
      });

      if (
        mainVideoContainerRef?.current &&
        selectedStreamRef?.current &&
        selectedStreamRef.current?.id === senderId
      ) {
        mainVideoContainerRef.current.srcObject = null;
        selectedStreamRef.current = null;
      }
    } else if (obj.type === 'toggle-raise-hand') {
      handleRaiseHand(obj.data);
    } else if (obj.type === 'stopScreenShare') {
      // stopScreenShare
      const { senderId } = obj.data;
      console.log('Screenshare stopped for:', senderId);

      // Update the state to remove the stream
      setStudentStreams((prevStreams) => {
        const updatedStreams = prevStreams.filter(
          (stream) => stream.id !== senderId,
        );
        return updatedStreams;
      });

      // console.log(
      //   mainVideoContainerRef?.current,
      //   selectedStreamRef?.current,
      //   senderId,
      // );

      if (
        mainVideoContainerRef?.current &&
        selectedStreamRef?.current &&
        selectedStreamRef.current?.id === senderId
      ) {
        // console.log('main video cleared');
        mainVideoContainerRef.current.srcObject = null;
        selectedStreamRef.current = null;
      }
    }
  };

  const handleMessageDirect = async (obj) => {
    if (obj.type === 'webrtc_offer') {
      const remotePeerId = obj.data.senderId;
      peerConnectionRef.current[remotePeerId].setRemoteDescription(
        new RTCSessionDescription(obj.data.sdp),
      );
      await createAnswer(peerConnectionRef.current[remotePeerId], remotePeerId);
    } else if (obj.type === 'webrtc_answer') {
      const remotePeerId = obj.data.senderId;
      peerConnectionRef.current[remotePeerId].setRemoteDescription(
        new RTCSessionDescription(obj.data.sdp),
      );
    } else if (obj.type === 'webrtc_ice_candidate') {
      const remotePeerId = obj.data.senderId;
      var candidate = new RTCIceCandidate({
        sdpMLineIndex: obj.data.label,
        candidate: obj.data.candidate,
      });
      peerConnectionRef.current[remotePeerId].addIceCandidate(candidate);
    } else if (obj.type === 'student-joined-the-room') {
      // console.log(obj.data);
      setStudentsArray((prev) => [...prev, obj.data]);
    }
  };

  useEffect(() => {
    if(isLoaded){
      console.log('isLoaded server api', isLoaded);
      console.log('is ice server gethered', iceServers);
      socket.connect();
      socket.on('messageRoom', handleMessageRoom);
      socket.on('messageDirect', handleMessageDirect);
      // socket.on('connect', handleConnect);
      return () => {
        // socket.off('connect', handleConnect);
        socket.off('messageRoom', handleMessageRoom);
        socket.off('messageDirect', handleMessageDirect);
        socket.disconnect();
        setStudentStreams([]);
        setStudentsArray([]);
      };
    }


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded, iceServers]);

  const handleConnect = () => {
    console.log('Connected to Socket.IO server');

    socket.emit('join', room, Constants.ROLE.TEACHER, async (obj) => {
      setIsRoomJoined(true);
      setReceivedMessage(false);
      localPeerIdRef.current = obj.socketId;
      // socket.on('message', (message) => {
      //   if (message.type === 'answer') {
      //     handleAnswer(message);
      //   } else if (message.type === 'candidate') {
      //     handleCandidate(message);
      //   } else if (message.type === 'student-joined-the-room') {
      //     setStudentsArray((prev) => [...prev, message.data]);
      //   } else if (message.type === 'student-left-the-room') {
      //     setStudentsArray((prev) => {
      //       let filteredArray = prev.filter((e) => {
      //         return e.socketId !== message.data.socketId;
      //       });
      //       return filteredArray;
      //     });
      //   }
      // });
    });
  };

  const setLocalStream = async () => {
    try {
      const stream = await navigator.mediaDevices.getDisplayMedia({
        video: true,
      });
      screenShareStreamRef.current = stream;

      // if student stream is selected remove and update ui
      if (selectedStreamRef.current) {
        document.getElementById(
          `studentsVideoContainer__${selectedStreamRef.current.id}`,
        ).style.outline = 'none';
        selectedStreamRef.current = null;
        setIsStudentScreenSharing(false);
      }

      // Show the screen share in the main video container
      if (mainVideoContainerRef.current) {
        mainVideoContainerRef.current.srcObject = stream;
      }
      setIsScreenSharing(true);
      // socket.emit('messageRoom', {
      //   type: 'student_can_join',
      // });
      addLocalTracks(true);
      stream.getVideoTracks()[0].onended = async () => {
        await stopScreenShare();
      };
    } catch (error) {
      console.error('Error sharing screen:', error);
    }
  };

  const stopScreenShare = async () => {
    screenShareStreamRef.current.getTracks().forEach((track) => {
      track.stop();
      for (let remotePeerId of remotePeerIdsRef.current) {
        const sender = peerConnectionRef.current[remotePeerId]
          .getSenders()
          .find((s) => s.track === track);
        if (sender) {
          peerConnectionRef.current[remotePeerId].removeTrack(sender);
        }
      }
    });
    screenShareStreamRef.current = null;
    if (mainVideoContainerRef.current) {
      mainVideoContainerRef.current.srcObject = null;
    }
    setIsScreenSharing(false);
    socket.emit('messageRoom', {
      type: 'stopScreenShare',
      data: {
        roomId: room,
        senderId: localPeerIdRef.current,
        roleId: Constants.ROLE.TEACHER,
      },
    });
  };

  const addLocalTracks = (sentToAll = false, remotePeerId) => {
    if (sentToAll) {
      for (let remotePeerId of remotePeerIdsRef.current) {
        if (screenShareStreamRef.current) {
          screenShareStreamRef.current.getTracks().forEach((track) => {
            peerConnectionRef.current[remotePeerId].addTrack(
              track,
              screenShareStreamRef.current,
            );
          });
        }
      }
    } else {
      if (screenShareStreamRef.current) {
        screenShareStreamRef.current.getTracks().forEach((track) => {
          peerConnectionRef.current[remotePeerId].addTrack(
            track,
            screenShareStreamRef.current,
          );
        });
      }
    }
  };

  const createAnswer = async (rtcPeerConnection, remotePeerId) => {
    let sessionDescription;
    try {
      sessionDescription = await rtcPeerConnection.createAnswer();
      rtcPeerConnection.setLocalDescription(sessionDescription);
    } catch (error) {
      console.error(error);
    }
    socket.emit('messageDirect', {
      type: 'webrtc_answer',
      data: {
        sdp: sessionDescription,
        roomId: room,
        senderId: localPeerIdRef.current,
        receiverId: remotePeerId,
      },
    });
  };

  const createOffer = async (rtcPeerConnection, remotePeerId) => {
    console.log('Creating offer from teacher');
    let sessionDescription;
    try {
      sessionDescription = await rtcPeerConnection.createOffer();
      rtcPeerConnection.setLocalDescription(sessionDescription);
    } catch (error) {
      console.error(error);
    }
    socket.emit('messageDirect', {
      type: 'webrtc_offer',
      data: {
        sdp: sessionDescription,
        roomId: room,
        senderId: localPeerIdRef.current,
        receiverId: remotePeerId,
      },
    });
  };

  const handleICECandidateEvent = (event, remotePeerId) => {
    if (event.candidate) {
      console.log(
        `Sending ICE Candidate from peer ${localPeerIdRef.current} to peer ${remotePeerId}`,
      );
      // console.log('Local ICE candidate:', event.candidate);
      // socket.emit('message', {
      //   type: 'candidate',
      //   candidate: event.candidate,
      // });
      if (event.candidate.type === 'srflx') {
        console.log('The STUN server is reachable!');
        console.log(`   Your Public IP Address is: ${event.candidate.address}`);
      }

      // If a relay candidate was found, notify that the TURN server works!
      if (event.candidate.type === 'relay') {
        console.log('The TURN server is reachable !');
      }
      socket.emit('message', {
        type: 'webrtc_ice_candidate',
        data: {
          senderId: localPeerIdRef.current,
          receiverId: remotePeerId,
          roomId: room,
          label: event.candidate.sdpMLineIndex,
          candidate: event.candidate.candidate,
        },
      });
    } else {
      console.log('All ICE candidates have been sent');
    }
  };

  const handleICEGatheringStateChange = (event, remotePeerId) => {
    console.log(
      'ICE gathering state:',
      peerConnectionRef.current[remotePeerId].iceGatheringState,
    );
  };

  const handleConnectionStateChange = (event, remotePeerId) => {
    console.log(
      'Connection state:',
      peerConnectionRef.current[remotePeerId].connectionState,
    );
  };

  const handleTrackEvent = (event, remotePeerId) => {
    const newStream = event.streams[0];
    console.log('New stream received:', remotePeerId);

    // Update the state with the new stream, ensuring only one stream is displayed
    setStudentStreams((prevStreams) => {
      // const updatedStreams = [{ id: remotePeerId, stream: newStream }];
      // console.log('Updated student streams:', updatedStreams.length);

      const updatedStreams = [...prevStreams];
      const idx = updatedStreams.findIndex(
        (stream) => stream.id === remotePeerId,
      );
      if (idx !== -1) {
        updatedStreams.splice(idx, 1, { id: remotePeerId, stream: newStream });
      } else {
        updatedStreams.push({ id: remotePeerId, stream: newStream });
      }
      return updatedStreams;
    });

    // // Create a new div element to contain the student video
    // let singleStudentVideoContainer = document.createElement('div');
    // singleStudentVideoContainer.onclick = function () {
    //   console.log(remotePeerId);
    //   mainVideoContainerRef.current.srcObject = newStream;
    // };

    // // Create a new video element for the student's video
    // let singleStudentVideo = document.createElement('video');
    // singleStudentVideoContainer.classList.add(
    //   styles.singleStudentVideoContainer,
    //   `studentsVideoContainer__${remotePeerId}`,
    // );
    // singleStudentVideo.autoplay = true;
    // singleStudentVideo.srcObject = newStream;
    // singleStudentVideoContainer.appendChild(singleStudentVideo);

    // // Clear the students video container and append the new video container
    // const studentsVideoContainer = document.getElementById(
    //   'studentsVideoContainer',
    // );
    // studentsVideoContainer.innerHTML = ''; // Clear existing content
    // studentsVideoContainer.appendChild(singleStudentVideoContainer);
  };

  const handleNegotiationneeded = (event, remotePeerId) => {
    // if (screenShareStreamRef.current) {
    console.log('handleNegotiationneeded');
    createOffer(peerConnectionRef.current[remotePeerId], remotePeerId);
    // }
  };

  const sendGroupMessage = (msg) => {
    const newMessage = {
      type: 'group',
      id: localPeerIdRef.current,
      msg,
      sender: username,
    };
    for (let remotePeerId of remotePeerIdsRef.current) {
      // dataChannelRef.current[remotePeerId].send(JSON.stringify(newMessage));
      const dataChannel = dataChannelRef.current[remotePeerId];

      if (dataChannel.readyState === 'open') {
        dataChannel.send(JSON.stringify(newMessage));
      } else {
        console.error(`DataChannel for peer ${remotePeerId} is not open.`);
        // Optionally handle reconnection or notify the user here.
      }
    }
    setGroupMessages((prev) => [...prev, newMessage]);
  };

  const sendDirectMessage = (peerId, msg) => {
    const newMessage = {
      type: 'direct',
      id: localPeerIdRef.current,
      msg,
      sender: localPeerIdRef.current,
      receiver: peerId,
    };
    dataChannelRef.current[peerId].send(JSON.stringify(newMessage));
    setDirectMessages((prev) => ({
      ...prev,
      [peerId]: [...(prev[peerId] || []), newMessage],
    }));
  };

  const handleRaiseHand = ({ peerId }) => {
    // const user = studentsArray.find((student) => student.socketId === peerId);
    setStudentsArray((prevStudents) =>
      prevStudents.map((student) => {
        if (student.socketId === peerId)
          return {
            ...student,
            didRaiseHand: !student?.didRaiseHand || false,
          };
        return student;
      }),
    );
    const video = document.getElementById(`studentsVideoContainer__${peerId}`);

    if (video) {
      video.classList.toggle(styles.raiseHand);
    }

    console.log(`${peerId} has raised the hands`);
  };

  const handleVideoClick = (streamData) => {
    // if (!isScreenSharing) return;
    if (screenShareStreamRef.current) {
      // Stop and remove old tracks
      screenShareStreamRef.current.getTracks().forEach((track) => {
        track.stop();
        for (const remotePeerId of remotePeerIdsRef.current) {
          const sender = peerConnectionRef.current[remotePeerId]
            .getSenders()
            .find((s) => s.track === track);
          if (sender) {
            peerConnectionRef.current[remotePeerId].removeTrack(sender);
          }
        }
      });
      screenShareStreamRef.current = null;
    }

    if (mainVideoContainerRef.current) {
      mainVideoContainerRef.current.srcObject = streamData.stream;
    }

    // Remove outline from previously selected stream
    if (selectedStreamRef.current) {
      const prevSelectedElement = document.getElementById(
        `studentsVideoContainer__${selectedStreamRef.current.id}`,
      );
      if (prevSelectedElement) {
        prevSelectedElement.style.outline = 'none';
      }
    }

    selectedStreamRef.current = streamData;

    // Add outline to newly selected stream
    const newSelectedElement = document.getElementById(
      `studentsVideoContainer__${streamData.id}`,
    );
    if (newSelectedElement) {
      newSelectedElement.style.outline = '2px solid #22d3ee';
    }

    // Send selected stream to others
    for (const remotePeerId of remotePeerIdsRef.current) {
      const peerConnection = peerConnectionRef.current[remotePeerId];
      if (!peerConnection) continue;

      if (streamData.stream) {
        // Remove old tracks associated with the screen sharing
        streamData.stream.getTracks().forEach((track) => {
          const existingSender = peerConnection
            .getSenders()
            .find((s) => s.track === track);
          if (existingSender) {
            peerConnection.removeTrack(existingSender);
          }
        });

        // Add new tracks from the selected stream
        streamData.stream.getTracks().forEach((track) => {
          peerConnection.addTrack(track, streamData.stream);
        });
      }
    }

    // if (selectedStreamRef.current) {
    //   document.getElementById(
    //     `studentsVideoContainer__${selectedStreamRef.current.id}`,
    //   ).style.outline = 'none';
    // }
    // selectedStreamRef.current = streamData;
    // document.getElementById(
    //   `studentsVideoContainer__${streamData.id}`,
    // ).style.outline = '2px solid #22d3ee';

    // // Send selected stream to others
    // for (const remotePeerId of remotePeerIdsRef.current) {
    //   const peerConnection = peerConnectionRef.current[remotePeerId];
    //   if (!peerConnection) continue;

    //   if (streamData.stream) {
    //     // Remove old tracks associated with the screen sharing
    //     streamData.stream.getTracks().forEach((track) => {
    //       const existingSender = peerConnection
    //         .getSenders()
    //         .find((s) => s.track === track);
    //       if (existingSender) {
    //         peerConnection.removeTrack(existingSender);
    //       }
    //     });

    //     // Add new tracks from the selected stream
    //     streamData.stream.getTracks().forEach((track) => {
    //       peerConnection.addTrack(track, streamData.stream);
    //     });
    //   }
    // }

    setIsStudentScreenSharing(true);
    setIsScreenSharing(false);
  };

  const handleResendStream = async () => {
    console.log('stream resent');
    try {
      if (screenShareStreamRef.current) {
        // Stop and remove old tracks
        screenShareStreamRef.current.getTracks().forEach((track) => {
          track.stop();
          for (const remotePeerId of remotePeerIdsRef.current) {
            const sender = peerConnectionRef.current[remotePeerId]
              .getSenders()
              .find((s) => s.track === track);
            if (sender) {
              peerConnectionRef.current[remotePeerId].removeTrack(sender);
            }
          }
        });
      }

      const newStream = await navigator.mediaDevices.getDisplayMedia({
        video: true,
      });
      screenShareStreamRef.current = newStream;

      // if student stream is selected remove and update ui
      if (selectedStreamRef.current) {
        document.getElementById(
          `studentsVideoContainer__${selectedStreamRef.current.id}`,
        ).style.outline = 'none';
        selectedStreamRef.current = null;
        setIsStudentScreenSharing(false);
      }

      if (mainVideoContainerRef.current) {
        mainVideoContainerRef.current.srcObject = newStream;
      }

      for (const remotePeerId of remotePeerIdsRef.current) {
        const peerConnection = peerConnectionRef.current[remotePeerId];
        if (!peerConnection) continue;

        // Remove old tracks associated with the previous screen share
        newStream.getTracks().forEach((track) => {
          const existingSender = peerConnection
            .getSenders()
            .find((s) => s.track === track);
          if (existingSender) {
            peerConnection.removeTrack(existingSender);
          }
        });

        // Add new tracks from the new stream
        newStream.getTracks().forEach((track) => {
          peerConnection.addTrack(track, newStream);
        });
      }

      setIsStudentScreenSharing(false);
    } catch (error) {
      console.error('Error resending stream:', error);
    }
  };

  const leaveCall = () => {
    socket.emit('messageRoom', {
      type: 'teacher-left-the-room',
      data: {
        roomId: room,
        // name: username,
        socketId: localPeerIdRef.current,
        senderId: localPeerIdRef.current,
      },
    });

    if (myVideoContainerRef.current) {
      myVideoContainerRef.current.srcObject = null;
    }
    if (mainVideoContainerRef.current) {
      mainVideoContainerRef.current.srcObject = null;
    }
    if (screenShareStreamRef.current) {
      screenShareStreamRef.current.getTracks().forEach((track) => track.stop());
      screenShareStreamRef.current = null;
    }
    remotePeerIdsRef.current.forEach((peerId) => {
      if (peerConnectionRef.current[peerId]) {
        peerConnectionRef.current[peerId].close();
        delete peerConnectionRef.current[peerId];
      }
      if (dataChannelRef.current[peerId]) {
        dataChannelRef.current[peerId].close();
        delete dataChannelRef.current[peerId];
      }
    });
    remotePeerIdsRef.current = [];
    setIsRoomJoined(false);
    navigate('/');
  };

  return (
    <div className="flex flex-col ">
      <div className="flex flex-col" style={{ position: 'relative' }}>
      <LeaveCallModal
          isOpen={confirmModal}
          onClose={handleCloseModal}
          onConfirm={handleConfirmLeave}
        />
        <div className="flex flex-col mx-4">
          <p
            className={`${styles['edfx-title']} text-primary font-jost font-extrabold`}
          >
            EDFX
          </p>
        </div>
        <div className={`${styles['presenter-info']} mx-4`}>
          Presenter: Teacher
        </div>
      </div>
      <div className="flex">
        <div
          // className={`${styles['mainVideoBox']}flex w-[79vw] mx-4`}
          className={`${styles.mainVideoBox} flex-shrink-0 transition-all duration-300 ease-in-out h-[calc(100vh-300px)] ${
            isHovering ? 'w-[30vw]' : 'w-[79vw]'
          } mx-4`}
          style={{ marginTop: '120px', position: 'relative' }}
        >
          <video
            className={clsx([styles.mainVideoContainer, 'object-fill'])}
            ref={mainVideoContainerRef}
            autoPlay
            playsInline
          ></video>
          {/* <video
          className={clsx(['ml-1', styles.myVideoContainer])}
          ref={myVideoContainerRef}
          autoPlay
          playsInline
        ></video> */}
        </div>
        {isHovering && (
          <div
            className={` ${styles.mainVideoBox} flex-shrink-0 w-[49vw] transition-all duration-100 -ml-2 h-[calc(100vh-300px)]`}
            style={{ marginTop: '120px', position: 'relative' }}
          >
            <video
              className={clsx([styles.mainVideoContainer, 'object-fill'])}
              autoPlay
              playsInline
              ref={(el) => {
                if (el && hoveredStream) el.srcObject = hoveredStream.stream;
              }}
            ></video>
          </div>
        )}
      </div>
      <div className="flex items-center justify-center gap-4 mt-28 mb-4">
        {isRoomJoined && (
          <>
            {!isScreenSharing ? (
              <div className="relative flex flex-col mt-3 -ml-2 group ">
                <button
                  className="flex items-center gap-2 px-3 py-2 border-2 rounded-lg shadow-md bg-sky-400 hover:bg-blue-500 text-white"
                  onClick={setLocalStream}
                >
                  <ScreenShare />  <span className='text-nowrap'>Share Screen</span>
                </button>
                <p className="absolute top-[-60px] left-1/2 transform -translate-x-1/2 translate-y-1/2 px-2.5 py-1 bg-sky-400 rounded-md text-center text-white whitespace-nowrap opacity-0 group-hover:opacity-100 transition-opacity border border-white">
                  Click this button to Share Screen
                </p>
              </div>
            ) : (
              <>
                <div className="relative flex flex-col mt-3 -ml-2 group">
                  <button
                    className="flex items-center gap-2 px-3 py-2 border-2 rounded-lg shadow-md bg-red-400 hover:bg-red-500 text-white border-white"
                    onClick={stopScreenShare}
                  >
                    <ScreenShareOff /> <span className='text-nowrap'>Stop Share Screen</span>
                  </button> 
                  <p className="absolute top-[-60px] left-1/2 transform -translate-x-1/2 translate-y-1/2 px-2.5 py-1 bg-red-400 rounded-md text-center text-white whitespace-nowrap opacity-0 group-hover:opacity-100 transition-opacity border border-white">
                    Click this button to Stop sharing screen
                  </p>
                </div>
                {isStudentScreenSharing && (
                  <div className="relative flex flex-col group mt-3">
                    <button
                      className="flex items-center gap-2 px-3 py-2 border-2 rounded-lg shadow-md bg-teal-700 hover:bg-teal-600 text-white border-white"
                      onClick={handleResendStream}
                    >
                      <MonitorUp /> <span className='text-nowrap'>Re-share my Screen</span>
                    </button>
                    <p className="absolute top-[-60px] left-1/2 transform -translate-x-1/2 translate-y-1/2 px-2.5 py-1 bg-teal-600 rounded-md text-center text-white whitespace-nowrap opacity-0 group-hover:opacity-100 transition-opacity border border-white">
                      Click this button to Re-share your Screen
                    </p>
                  </div>
                )}
              </>
            )}
          </>
        )}
        {!isRoomJoined ? (
          <div className="relative flex flex-col -ml-2  group">
            <button
              className="flex items-center gap-2 px-10 py-4 border-1 shadow-md rounded-lg bg-sky-400 hover:bg-blue-500 text-white text-nowrap"
              onClick={async () => {
                await handleChangeRoomStatus(room, 'started');
                handleConnect();
              }}
            >
              <PhoneCall /> <span className='text-nowrap'>Join Call</span>
            </button>
            <p className="absolute top-[-60px] left-1/2 transform -translate-x-1/2 translate-y-1/2 px-2.5 py-1 bg-sky-400 rounded-md text-center text-white whitespace-nowrap opacity-0 group-hover:opacity-100 transition-opacity border border-white">
              Click this button to Join Call
            </p>
          </div>
        ) : (
          <div className="relative flex flex-col mt-3 group">
            <button
              className="flex items-center gap-2 px-3 py-2 border-2 rounded-lg shadow-md bg-red-400 hover:bg-red-500 text-white  border-white"
              onClick={async () => {
                await handleChangeRoomStatus(room, 'not started');
                leaveCall();
              }}
            >
              <PhoneOff /> <span className='text-nowrap'>Leave Call</span>
            </button>
            <p className="absolute top-[-60px] left-1/2 transform -translate-x-1/2 translate-y-1/2 px-2.5 py-1 bg-red-400 rounded-md text-center text-white whitespace-nowrap opacity-0 group-hover:opacity-100 transition-opacity border border-white">
              Click this button to leave Call
            </p>
          </div>
        )}
      </div>

      {studentStreams.length <= 4 ? (
        <div
          id="studentsVideoContainer"
          className="m-2 grid grid-cols-4 gap-4 w-3/5 mx-2"
        >
          {studentStreams.map((streamData, index) => (
            <div
              className={styles.singleStudentVideoContainer}
              id={`studentsVideoContainer__${streamData.id}`}
              key={streamData.id}
              onClick={() => handleVideoClick(streamData)}
            >
              <video
                className="w-full h-40 object-cover mb-2 rounded-md shadow-lg overflow-hidden"
                autoPlay
                playsInline
                ref={(el) => {
                  if (el) el.srcObject = streamData.stream;
                }}
                onMouseEnter={() => handleMouseEnter(streamData)}
                onMouseLeave={handleMouseLeave}
              />
              <p className="text-center">
                {studentsArray.find(
                  (student) => student.socketId === streamData.id,
                )?.name || ''}
              </p>
            </div>
          ))}
        </div>
      ) : (
        <VideoSlider
          streams={studentStreams}
          onVideoSelect={handleVideoClick}
          studentsArray={studentsArray}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        />
      )}
      {/* <div id="studentsVideoContainer" className=" m-2 grid grid-cols-4 gap-4 w-3/5"> */}
      {/* <div>
          <video
            className={clsx([
              styles.studentsVideoContainer,
            ])}
            ref={studentVideoContainer1}
            autoPlay
            playsInline
          ></video>
        </div>
        <div>
          <video
            className={clsx([
              styles.studentsVideoContainer,
            ])}
            ref={studentVideoContainer2}
            autoPlay
            playsInline
          ></video>
        </div>
        <div>
          <video
            className={clsx([
              styles.studentsVideoContainer,
            ])}
            ref={studentVideoContainer2}
            autoPlay
            playsInline
          ></video>
        </div>
        <div>
          <video
            className={clsx([
              styles.studentsVideoContainer,
            ])}
            ref={studentVideoContainer2}
            autoPlay
            playsInline
          ></video>
        </div> */}
      {/* </div> */}
      <Chat
        localPeerId={localPeerIdRef.current}
        usersArray={studentsArray}
        groupMessages={groupMessages}
        directMessages={directMessages}
        sendGroupMessage={sendGroupMessage}
        sendDirectMessage={sendDirectMessage}
        teacherView={true}
        receivedMessage={receivedMessage}
        isRoomJoined={isRoomJoined}
        // chatColorChange = {chatColorChange}
      />
    </div>
  );
}

export default TeacherRoom;
