import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getAllRoomAction } from '../../action/roomAction';
import { getProfileAction } from '../../action/profileAction';
import { socket } from '../../socket';
import { Icon, Info } from 'lucide-react';

const Studenthome = () => {
  const navigate = useNavigate();
  const [roomData, setRoomData] = useState([]);
  const [username, setUsername] = useState('');
  const [dataFetched, setDataFetched] = useState(false);
  const [roomStatus, setRoomStatus] = useState({}); // Track status for each room

  const isLoggedIn = !!localStorage.getItem('token');

  useEffect(() => {
    // Connect socket and setup listener
    socket.connect();
    console.log('Socket connected:', socket);

    const setupSocketListener = () => {
      console.log('Setting up socket listeners for teacher events');
    
      const handleTeacherEvent = (response) => {
        console.log('Received teacher event:', response);
        const { roomId, message } = response;
        
        // Update the status of the room based on the received message
        setRoomStatus(prevStatus => ({
          ...prevStatus,
          [roomId]: message === 'teacher joined' ? 'started' : 'not started',
        }));
      };

      const handleCreateRoomEvent = () => {
        console.log('Received createRoom event');
        fetchRoomData();
      };
    
      socket.on('teacher-joined', handleTeacherEvent);
      socket.on('teacher-left-the-room', handleTeacherEvent);
      socket.on('teacher-created-the-room', handleCreateRoomEvent);

    };
    
    setupSocketListener();    

    const fetchRoomData = async () => {
      if (isLoggedIn && !dataFetched) { // Check if data hasn't been fetched
        try {
          const data = await getAllRoomAction();
          setRoomData(data);

          // Initialize room statuses
          const initialStatus = data.reduce((acc, room) => {
            acc[room.url] = 'not started'; // Default status
            return acc;
          }, {});
          setRoomStatus(initialStatus);

          setDataFetched(true); // Set dataFetched to true after successful fetch
        } catch (error) {
          console.log('Error fetching room data:', error.response?.data?.error || error.message);
          toast.error(
            `Error fetching room data due to: ${error.response?.data?.error || error.message}`,
            {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
        }
      }
    };

    fetchRoomData();

    // Clean up
    // return () => {
    //   console.log('Cleaning up socket listeners');
    //   socket.off('teacher-joined');
    //   socket.off('teacher-left-the-room');
    //   socket.disconnect();
    // };
  }, [isLoggedIn, dataFetched]);

  useEffect(() => {
    const fetchProfileData = async () => {
      if (isLoggedIn) {
        try {
          const data = await getProfileAction();
          setUsername(`${data.firstName} ${data.lastName}`);
        } catch (error) {
          console.log('Error fetching profile data:', error.response?.data?.error || error.message);
          toast.error(
            `Error fetching profile data due to: ${error.response?.data?.error || error.message}`,
            {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
        }
      }
    };

    fetchProfileData();
  }, [isLoggedIn]);

  const handleJoinRoom = (roomUrl) => {
    const role = localStorage.getItem('role');
    navigate(`/${role}/${username}/${roomUrl}`);
  };

  return (
    <div className="container relative grid h-[50%] flex-col">
      <ToastContainer />
      <div className="grid grid-cols-10 w-full h-full">
        <div className="col-span-7 flex flex-col items-start my-4 space-y-2">
          <div className="flex w-full">
            <p className="text-violet-900 text-4xl font-medium mx-4 italic mt-40">
              "Plan !! Schedule !! Attend"
            </p>
          </div>

          <div className="flex w-3/4 my-5">
            {roomData.length > 0 ? (
              <table className="w-full text-sm text-left rtl:text-right text-black">
                <thead className="text-md text-black bg-gray-50 font-medium ">
                  <tr>
                    <th scope="col" className="px-6 py-3 text-nowrap text-center">Sr No.</th>
                    <th scope="col" className="px-6 py-3 text-nowrap text-center">Title</th>
                    <th scope="col" className="px-6 py-3 text-nowrap text-center">Start Time</th>
                    <th scope="col" className="px-6 py-3 text-nowrap text-center">End Time</th>
                    <th scope="col" className="px-5 py-3 text-nowrap text-center">Grade</th>
                    <th scope="col" className="px-5 py-3 text-nowrap text-center -ml-1">Join</th>
                  </tr>
                </thead>
                <tbody>
                  {roomData.map((room, index) => (
                    <tr className="bg-white border-b" key={index}>
                      <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap text-center">
                        {index + 1}
                      </th>
                      <td className="px-6 py-4 text-center">{room.title}</td>
                      <td className="px-6 py-4 text-center">{room.startTime}</td>
                      <td className="px-6 py-4 text-center">{room.endTime}</td>
                      <td className="px-6 py-4 text-center">{room.grade}</td>
                      <td className="px-5 py-4 text-center">
                        <button
                          type="button"
                          onClick={() => handleJoinRoom(room.url)}
                          className={`text-white bg-cyan-400 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-6 py-2.5 m-2 text-nowrap -ml-1 ${roomStatus[room.url] === 'not started' ? 'cursor-not-allowed opacity-50' : ''}`}
                          title={roomStatus[room.url] === 'started' ? "Meeting started" : "Meeting not yet started"}
                          disabled={roomStatus[room.url] === 'not started'}
                        >
                          Attend Class
                        </button>
                      </td>
                      <td className="px-2 py-4">
                        {roomStatus[room.url] === 'not started' && (
                          <div className="flex items-center">
                            <button
                              className="ml-1 text-gray-400 cursor-pointer"
                              size={16}
                              title="Attend button will be enabled once the teacher starts the class"
                            >
                              <Info/>
                            </button>
                          </div>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p className="text-black text-lg font-medium mx-4 mt-8 px-6 py-4 shadow-md bg-violet-300 border-t-2 border-b-2 border-cyan-800">
                No classroom currently.
              </p>
            )}
          </div>

          <div className="flex w-full my-3">
            <p className="text-gray-700 text-lg font-normal mx-4 mt-8">
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
              <br />
              industry's standard dummy text ever since the 1500s, when an unknown printer took a galley
            </p>
          </div>
        </div>

        <div className="col-span-3 flex flex-col items-start my-4 space-y-2">
          <img
            className=""
            src="/images/navbar/mainhome.png"
            alt="Main Home"
          />
        </div>
      </div>
    </div>
  );
};

export default Studenthome;
