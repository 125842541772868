import React,{ useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { deleteRoomAction, getRoomAction } from '../../action/roomAction';

const TeacherHome = () => {
  const navigate= useNavigate();
  const[roomData,setRoomData]=useState('')
  const [isDelete, setIsDelete] = useState(false)

    // const [tooltipVisible, setTooltipVisible] = useState(false);
    // const [copied, setCopied] = useState(false);
    // const [roomCreated, setRoomCreated] = useState(false);
    // const [roomUrl, setRoomUrl] = useState('');
    // const inputRef = useRef(null);

  //   const generateRoomId = () => {
  //     // return Math.random().toString(36).substring(2, 15);  // string function as roomID
  //     return Math.floor(1000000 + Math.random() * 9000000);   
  // };

  const handleDeleteRoom = async (roomId) => {
    try {
      await deleteRoomAction(roomId);
      
      toast.success(`Classroom deleted successfully!`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setIsDelete(!isDelete);
  

    } catch (error) {
      console.error('Error deleting room:', error.response?.data?.error);
      toast.error(`Classroom cannot be deleted. ${error.response?.data?.error}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }


  const handleRoomAction = () => {
    navigate('/createroom')
    // if (roomCreated) {
    //     // If room is already created, join the room
    //     console.log('room url', roomUrl)
    //     navigate(roomUrl);
    // } else {
    //     // Create a new room
        // const roomId = generateRoomId();
        // const newRoomUrl = `/teacher/${roomId}`;
        // console.log('Created room URL:', newRoomUrl);
        // setRoomUrl(newRoomUrl);
        // setRoomCreated(true);
        // if (inputRef.current) {
        //   inputRef.current.value = `${window.location.origin}/student/username/${roomId}`;
        // }
    // }
};

  // const handleMouseEnter = () => {
  //   setTooltipVisible(true);
  // };

  // const handleMouseLeave = () => {
  //   setTooltipVisible(false);
  // };

  

  // const handleCopyToClipboard = () => {
  //   if (inputRef.current) {
  //     const inputValue = inputRef.current.value;
  //     setCopied(true)
  //     navigator.clipboard.writeText(inputValue).then(() => {
  //       setTimeout(() => {
  //           setCopied(false);
  //         }, 1000);
        
  //     }).catch(err => {
  //       // Optionally handle error
  //       console.error('Failed to copy to clipboard:', err);
  //     });
  //   }
  // };
  const isLoggedIn = !!localStorage.getItem('token');

  useEffect(() => {
    
    const fetchRoomData = async () => {
      if (isLoggedIn) {
        try {
          const data = await getRoomAction();
          setRoomData(data);
          
        } catch (error) {
          console.log(
            'Error fetching room data:',
            error.response.data.error,
          );
          toast.error(
            `Error fetching room data due.${error.response.data.error}`,
            {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            },
          );
        }
      }
    };
 
    fetchRoomData();
    
  }, [isLoggedIn,isDelete])
  
  const handleJoinRoom = (roomUrl) => {

    const role= localStorage.getItem('role');
    const room = roomUrl;
    navigate(`/${role}/${room}`);
    console.log(roomUrl)
  };

  return (
    <div className="container relative grid h-[50%] flex-col ">
      <ToastContainer />
      <div className="grid grid-cols-10 w-full h-full">
        
        <div className="col-span-7 flex flex-col items-start my-4 space-y-2">
          <div className="flex w-full">
            <p className="text-violet-900 text-4xl font-medium mx-4 italic mt-40">
              " Plan !! Schedule !! Attend "
            </p>
          </div>

          <div className="flex w-full my-3 ">
            <button
              type="submit"
              className="text-white bg-cyan-800 font-medium rounded-lg text-lg shadow-md px-8  mx-4 mt-10 h-12 whitespace-nowrap"

              onClick={handleRoomAction}
            >
              Create Classroom
              {/* {roomCreated?'Join Room ':'Create Room'} */}
            </button>
            {/* <input type='text' placeholder='Room Link' className='border border-gray-300 rounded-lg px-8  mx-4 mt-10 w-[400px] h-12'
            ref={inputRef}
            /> */}
            {/* <div
              className="relative flex items-center"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onClick={handleCopyToClipboard}

            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                className="ml-1 mt-10 cursor-pointer"
              >
                <path
                  d="M20 9H11C9.89543 9 9 9.89543 9 11V20C9 21.1046 9.89543 22 11 22H20C21.1046 22 22 21.1046 22 20V11C22 9.89543 21.1046 9 20 9Z"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5 15H4C3.46957 15 2.96086 14.7893 2.58579 14.4142C2.21071 14.0391 2 13.5304 2 13V4C2 3.46957 2.21071 2.96086 2.58579 2.58579C2.96086 2.21071 3.46957 2 4 2H13C13.5304 2 14.0391 2.21071 14.4142 2.58579C14.7893 2.96086 15 3.46957 15 4V5"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              {tooltipVisible && (
                <div className="absolute z-10 px-3 py-2 text-sm font-medium text-white bg-gray-800 rounded shadow-sm opacity-100 -translate-y-full text-nowrap">
                  {copied ? 'Copied' : 'Copy to clipboard'}


                </div>
              )}
              
            </div> */}
          </div>
          <div className="flex w-3/4 my-5">
            {roomData.length>0 ? (
              <>
              
              <table className="w-full text-sm text-left rtl:text-right text-black mt-2">
              <thead className="text-md text-black  bg-gray-50 font-medium">
                  <tr>
                      <th scope="col" className="px-6 py-3 text-nowrap">
                         Sr No.
                      </th>
                      <th scope="col" className="px-6 py-3 text-nowrap text-center">
                          Title
                      </th>
                      <th scope="col" className="px-6 py-3 text-nowrap">
                          Start Time
                      </th>
                      <th scope="col" className="px-6 py-3 text-nowrap">
                          End Time
                      </th>
                      <th scope="col" className="px-5 py-3 text-nowrap">
                          Grade
                      </th>
                      <th scope="col" className="px-5 py-3 text-nowrap  text-center">
                          Join
                      </th>
                      <th scope="col" className="px-5 py-3 text-nowrap text-center">
                          Actions
                      </th>
                  </tr>
              </thead>
                <tbody>
                  {/* Assuming roomData is an array of rooms */}
                  {roomData.map((room, index) => (
                    <tr className="bg-white border-b "key={index}>
                    <th scope="row" className="px-6 py-4 text-center">
                    {index + 1}
                    </th>
                    <td className="px-6 py-4 text-center">
                        {room.title}
                    </td> 
                    <td className="px-6 py-4 text-center">
                        {room.startTime}
                    </td>
                    <td className="px-6 py-4 text-center">
                    {room.endTime}
                    </td>
                    <td className="px-6 py-4 text-center">
                    {room.grade}
                    </td>
                    <td className="px-5 py-4 text-center">
                    <button type="button"  onClick={() => handleJoinRoom(room.url)} className=" text-white bg-cyan-400 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-6 py-2.5 m-2 text-nowrap ">
                      Start Class
                      </button>
                    </td>
                    <td className="px-5 py-4">
                    <button type="button"  onClick={() => handleDeleteRoom(room.id)} className=" text-white bg-red-400 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-6 py-2.5 m-2  ">
                      Delete
                      </button>
                    </td>
                    
                </tr>
                  ))}
                </tbody>
              </table>
              </>
            ) : (
              
              <p className="text-black text-lg font-medium mx-4 mt-8 px-6 py-4 shadow-md bg-violet-300  border-t-2 border-b-2 border-cyan-800 ">
                No classroom currently.
              </p>
              
            )}
          </div>

          <div className="flex w-full my-3 ">
            <p className="text-gray-700 text-lg font-normal mx-4 mt-8">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the
              <br />
              industry's standard dummy text ever since the 1500s, when an unknown
              printer took a galley
            </p>
            
          </div>
        </div>

        
        <div className="col-span-3 flex flex-col items-start my-4  space-y-2">
          <img className=" " src="/images/navbar/mainhome.png" alt="Main Home" />
        </div>
      </div>
    </div>
  );
};

export default TeacherHome;
