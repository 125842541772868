import { createBrowserRouter } from 'react-router-dom';
import Home from '../components/home/home';
import { Constants } from '../config/Constants';
import LogIn from '../components/auth/login';
import Signup from '../components/auth/signup';
import LandingPage from '../components/LandingPage/landingpage';
import MainHome from '../components/mainhome/mainhome';
import Privateroute from './Privateroute';
import Profile from '../components/Profile/profile';
import Classroomcreation from '../components/mainhome/classcreation';

const router = createBrowserRouter([
  {
    path: '/:role/:room',
    element: (
      <Privateroute>
        <Home role={Constants.ROLE.TEACHER} />
      </Privateroute>
    ),
  },
  {
    path: '/:role/:username/:room',
    element: (
      <Privateroute>
        <Home role={Constants.ROLE.STUDENT} />
      </Privateroute>
    ),
  },
  {
    path: '/login',
    element: <LogIn />,
  },
  {
    path: '/signup',
    element: <Signup />,
  },
  // {
  //   path: '/',
  //   element: <LandingPage />,
  // },
  {
    path: '/',
    element: (
      // <Privateroute>
        <MainHome />
      // </Privateroute>
    ),
  },
  {
    path: '/profile',
    element: (
      <Privateroute>
        <Profile />
      </Privateroute>
    ),
  },
  {
    path: '/createroom',
    element: (
      <Privateroute>
        <Classroomcreation />
      </Privateroute>
    ),
  },
]);

export default router;
