import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LandingNav from '../LandingPage/Landingnav/landingnav';
import Landingfooter from '../LandingPage/LandingFooter/landingfooter';
import { createRoomAction } from '../../action/roomAction';
import { socket } from '../../socket';


const Classroomcreation = () => {
  
  const navigate = useNavigate();
  const [title, setTitle]=useState('')
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const[grade,setGrade]=useState('1');
  const[roomId,setRoomId]=useState('')
  const [isSubmitting, setIsSubmitting] = useState(false);

  const generateRoomId = () => {
    // return Math.random().toString(36).substring(2, 15);  // string function as roomID
    return Math.floor(1000000 + Math.random() * 9000000);
  };
  useEffect(() => {
    setRoomId(generateRoomId().toString());
  }, []);

  const handlemainroom = () => {
    navigate('/');
  };
  const clearForm = () => {
    setTitle('');
    setStartTime('');
    setEndTime('');
    setGrade('');
    setRoomId(generateRoomId().toString());
    
  };

  const createClass = (roomData) => {
    socket.emit('createRoom', {
      type: 'teacher-created-the-room',
      data: {
        roomData: roomData,
      },
    });
  }

  const handlesubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    if (endTime <= startTime) {
      toast.info('End time must be greater than start time.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
      setIsSubmitting(false);
      return;
    }




    // const [startHour, startMinute] = startTime.split(':').map(Number);
    // const [endHour, endMinute] = endTime.split(':').map(Number);

    // const now = new Date();
    // const currentHour = now.getHours();
    // const currentMinute = now.getMinutes();

    // if (startHour < currentHour || (startHour === currentHour && startMinute < currentMinute)) {
    //   toast.error('Start time cannot be in the past.', {
    //     position: "top-right",
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //   });
    //   setIsSubmitting(false);
    //   return;
    // }

    // const isEndTimeNextDay = endHour < startHour || (endHour === startHour && endMinute <= startMinute);

    //   if (!isEndTimeNextDay && endHour <= startHour && endMinute <= startMinute) {
    //     toast.error('End time must be greater than start time.', {
    //       position: "top-right",
    //       autoClose: 5000,
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       progress: undefined,
    //     });
    //     setIsSubmitting(false);
    //     return;
    //   }
    const userId= localStorage.getItem('id');
    const role = localStorage.getItem('role');
    // const url = `${window.location.origin}/${role}/${roomId}`; 
  
    const roomData = {
      title,
      startTime,
      endTime,
      userId,
      grade,
      url:roomId, 
    };
    
  
    try {
      const response = await createRoomAction(roomData);
      // console.log('Room created successfully:', response);
      toast.success('Classroom created sucessfully!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setTimeout(() => {
        navigate('/');
      }, 1000);
      createClass(roomData)
      
      
    } catch (error) {
      // console.error('Error creating room:', error.response.data.error);
      // try {
        // const errorMessage = error?.response?.data?.error || 'Unknown error occurred';
        toast.error(`Classroom cannot be created as ${error.response.data.error}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      // } catch (err) {
      //   console.error("Error showing toast notification:", err);
      // }
      
      
    }
    finally {
      setIsSubmitting(false);
    }
    clearForm();
  };


  
  return (
    <>

      <LandingNav />
      <ToastContainer />
      <div className="container relative grid h-[50%] flex-col ">
        <div className="grid grid-cols-10 w-full h-full">
          <div className="col-span-7 flex flex-col items-center my-4 space-y-2">
            <div className="max-w-sm mx-auto my-40">
              <form
                className="border-2 border-gray-300 p-8 rounded-lg shadow-xl bg-white"
                onSubmit={handlesubmit}
              >
                <div className="flex items-center justify-between mb-6">
                  <button
                    className="p-1 mr-2 mt-1 hover:bg-gray-100 rounded-full focus:outline-none"
                    onClick={handlemainroom}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                        stroke="#08BCD4"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12 8L8 12L12 16"
                        stroke="#08BCD4"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M16 12H8"
                        stroke="#08BCD4"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                  <p className="text-violet-900 text-2xl font-semibold drop-shadow-sm">
                    Create Classroom
                  </p>
                </div>
                <div className="mb-5">
                  <label
                    htmlFor="title"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Title
                  </label>
                  <input
                    type="text"
                    id="title"
                    placeholder="Ex: Grade 5 Mathematics"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    required
                  />
                </div>
  
                <div className="grid grid-cols-2 gap-4 mb-5">
                  <div>
                    <label
                      htmlFor="start-time"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Start Time
                    </label>
                    <input
                      type="time"
                      id="startTime"
                      value={startTime}
                      onChange={(e) => setStartTime(e.target.value)}
                      className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      required
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="end-time"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      End Time
                    </label>
                    <input
                      type="time"
                      id="endTime"
                      value={endTime}
                      onChange={(e) => setEndTime(e.target.value)}
                      className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      required
                    />
                  </div>
                </div>
                <div className="mb-5">
                  <label
                    htmlFor="grade"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Select Grade
                  </label>
                  <select
                    id="grade"
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-violet-500 focus:border-violet-500 block w-full p-2 "
                    required
                    value={grade}
                    onChange={(e) => setGrade(e.target.value)}
                  >
                    <option
                      value="1"
                      className="mt-1 rounded-lg p-1 text-black"
                    >
                      Grade 1
                    </option>
                    <option value="2">Grade 2</option>
                    <option value="3">Grade 3</option>
                    <option value="4">Grade 4</option>
                    <option value="5">Grade 5</option>
                    <option value="6">Grade 6</option>
                    <option value="7">Grade 7</option>
                    <option value="8">Grade 8</option>
                    <option value="9">Grade 9</option>
                    <option value="10">Grade 10</option>

                  </select>
                </div>
                <div className="mb-5">
                  <label
                    htmlFor="roomId"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Room ID
                  </label>
                  <input
                    type="text"
                    id="roomId"
                    value={roomId}
                    onChange={(e) => setRoomId(e.target.value)}
                    placeholder="Ex: Room1234"
                    className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    readOnly
                  />
                </div>
                <button
                  type="submit"
                  className="text-white bg-violet-900 hover:bg-violet-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full py-2.5 text-center"
                >
                  {isSubmitting ? (
                    <div className="flex justify-center">
                      <svg
                        aria-hidden="true"
                        className="w-6 h-6 text-gray-200 animate-spin fill-blue-600"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                      <span className="sr-only text-white">
                        Creating Classroom...
                      </span>
                    </div>
                  ) : (
                    'Create Classroom'
                  )}
                </button>
              </form>
            </div>
          </div>
  
          <div className="col-span-3 flex flex-col items-center my-12 space-y-2">
            <img
              className="w-full"
              src="/images/navbar/mainhome.png"
              alt="Main Home"
            />
          </div>
        </div>
      </div>
  
      <Landingfooter />
    </>
  );
  
};

export default Classroomcreation;