import axiosInstance from '../api/axiosInstance';

export const getProfileAction = async () => {
    try {
      const userId = localStorage.getItem('id');
      if (!userId) {
        throw new Error('User ID not found in local storage');
      }
  
      const response = await axiosInstance.get(`/user/${userId}`);
      
      if (response.data) {
        return response.data;
      } else {
        console.log('Profile Response structure:', response.data);
        throw new Error('Unexpected response structure');
      }
    } catch (error) {
      console.error('Get profile error:', error);
      throw error;
    }
  };