import React from 'react'
import LandingNav from './Landingnav/landingnav'
import Landingfooter from './LandingFooter/landingfooter'

const LandingPage = () => {
  return (
    <>
    {/* <LandingNav/>
    <Landingfooter/> */}
    </>
  )
}

export default LandingPage