import React from 'react';
import clsx from 'clsx';
import styles from './navbar.module.scss';
// import Screen from '../../screenshare/screenshare';


function Navbar() {
  return (
<>
    <div className="flex flex-wrap p-4">
      <div className={clsx(['w-1/2', styles.leftSection])}>
        <img className="" src="/images/navbar/logo.png" alt="" />
      </div>
      <div className={clsx(['w-1/2 flex justify-end gap-4', styles.rightSection])}>
        <div>
          <img src="/images/navbar/bell.png" alt="" />
        </div>
        <div>
          <img className="" src="/images/navbar/log-out.png" alt="" />
        </div>
        <div>
          <img className="" src="/images/navbar/profile.png" alt="" />
        </div>
      </div>
    </div>
    {/* <Screen/> */}
    </>
  );
}

export default Navbar;
