import clsx from 'clsx';
import React, { useState, useEffect } from 'react';
import styles from './chat.module.scss';
import ChatUsers from './chat-users/ChatUsers';
import GroupChat from './group-chat/GroupChat';
import IndividualUserChat from './individual-user-chat/IndividualUserChat';
import { MessageSquareDot } from 'lucide-react';

function Chat({
  localPeerId,
  usersArray,
  groupMessages,
  directMessages,
  sendGroupMessage,
  sendDirectMessage,
  teacherView,
  receivedMessage,
  isRoomJoined
}) {
  const [activeChatWindow, setActiveChatWindow] = useState(teacherView ? 1 : 2);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [teacherChat, setTeacherChat] = useState(false);
  const [receivedMessage1, setReceivedMessage1] = useState(receivedMessage);
  const [userRole, setUserRole] = useState('');

  useEffect(() => {
    const role = localStorage.getItem('role');
    setUserRole(role);
    if (role === 'student') {
      setActiveChatWindow(2);
    }
  }, []);

  const toggleChatChange = () => {
    setTeacherChat(!teacherChat);
    if (userRole === 'student' && !teacherView) {
      const teacher = usersArray.find((user) => user.name === 'teacher');
      if (teacher) {
        setSelectedUserId(teacher.socketId);
        setActiveChatWindow(3);
      }
    }
  };

  useEffect(() => {
    if (activeChatWindow === 2 && receivedMessage1) {
      setReceivedMessage1(false);
    }
  }, [activeChatWindow, receivedMessage1]);

  return (
    <div className="fixed bottom-0 right-0 h-full flex flex-col justify-between bg-white border md:w-1/5">
      <div className={clsx(['flex p-4', styles.firstSection])}>
        <div className="w-1/2">
          <p>Chat</p>
          <div className="flex items-center gap-2">
            <div className={`w-3 h-3 rounded-full bg-green-500`}></div>
            {teacherView ? (
              <span>Students ({usersArray?.length || 0})</span>
            ) : (
              <span className="text-nowrap">Teacher Online</span>
            )}
          </div>
        </div>
        <div className="w-1/2 flex justify-end mt-4">
          <img className="h-14" src="/images/navbar/mainprofile.png" alt="" />
        </div>
      </div>
      {activeChatWindow === 1 && (
        <ChatUsers
          chatUsers={usersArray}
          setSelectedUserId={(userId) => {
            setActiveChatWindow(3);
            setSelectedUserId(userId);
          }}
        />
      )}
      {activeChatWindow === 2 && (
        <GroupChat
          localPeerId={localPeerId}
          groupMessages={groupMessages}
          sendGroupMessage={sendGroupMessage}
          isRoomJoined={isRoomJoined}
          teacherView={teacherView}
        />
      )}
      {activeChatWindow === 3 && (
        <IndividualUserChat
          localPeerId={localPeerId}
          directMessages={directMessages}
          selectedUserId={selectedUserId}
          usersArray={usersArray}
          setActiveChatWindow={setActiveChatWindow}
          sendDirectMessage={sendDirectMessage}
          teacherView={teacherView}
          isRoomJoined={isRoomJoined}
        />
      )}
      <div
        className={clsx([
          'flex p-2 justify-evenly',
          styles.selectChatTypeSection,
        ])}
      >
        <button
          className={clsx('w-1/2 px-3 py-1 text-nowrap', {
            'border bg-white rounded-lg font-semibold text-nowrap':
              activeChatWindow === 1 ||activeChatWindow === 3,
          })}
          onClick={() => {
            setActiveChatWindow(1);
            toggleChatChange();
          }}
        >
          {userRole === 'student' ? 'Ask Teacher' : 'Direct Message'}
        </button>
        <button
          className={clsx('w-1/2 px-3 py-1 text-nowrap flex items-center gap-2', {
            'border bg-white rounded-lg font-medium text-nowrap':
              activeChatWindow === 2,
          })}
          onClick={() => {
            setActiveChatWindow(2);
            setReceivedMessage1(false);
          }}
        >
          <MessageSquareDot size={18} className={`${receivedMessage1 ? "text-[green]" : "text-[blue]"}`}/>
          {userRole === 'student' ? 'Response' : 'Group Chat'}
        </button>
      </div>
    </div>
  );
}

export default Chat;
