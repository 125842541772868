import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { socket } from '../../../socket';
import styles from './student-room.module.scss';
import clsx from 'clsx';
import { Constants } from '../../../config/Constants';
import Chat from '../../chat/Chat';
import {
  Hand,
  PhoneCall,
  PhoneOff,
  ScreenShare,
  ScreenShareOff,
} from 'lucide-react';
import { getIceServers } from '../../../action/authAction';
import LeaveCallModal from '../LeaveRoom/LeaveCallModal';

function StudentRoom({ room, username }) {
  // const iceServers = {
  //   iceServers: [
  //     {
  //       "urls": "stun:global.stun.twilio.com:3478"
  //   },
  //   {
  //       "username": "e3703f4d48526603d0bd6863db75773024c1d793efe98f70cc41faaa4ac08d69",
  //       "urls": "turn:global.turn.twilio.com:3478?transport=udp",
  //       "credential": "8P82CePg10dCICmQ9vBxkZP8b6Ma5nD74a3eV6nxZR0="
  //   }
  //   ],
  // };
  const [iceServers, setIceServers] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const navigate = useNavigate();
  const peerConnectionRef = useRef([]);
  const hostPeerIdRef = useRef(null);
  const dataChannelRef = useRef([]);
  const remotePeerIdsRef = useRef([]);
  const mainVideoContainerRef = useRef(null);
  const myVideoContainerRef = useRef(null);
  const localPeerIdRef = useRef(null);
  const screenShareStreamRef = useRef(null);
  const [isRoomJoined, setIsRoomJoined] = useState(false);
  const [isScreenSharing, setIsScreenSharing] = useState(false);
  const [usersArray, setUsersArray] = useState([]);
  const [groupMessages, setGroupMessages] = useState([]);
  const [directMessages, setDirectMessages] = useState([]);
  const [didRaiseHand, setDidRaiseHand] = useState(false);
  const [teacherArray, setTeacherArray] = useState([]);
  const [confirmModal, setConfirmModal] = useState(false);

  useEffect(() => {
    const fetchIceServers = async () => {
      try {
        // Call the API function to fetch ICE servers
        const { iceServers } = await getIceServers();

        console.log('Fetched ICE servers:', iceServers);
        setIceServers(iceServers);
        setIsLoaded(true);
      } catch (error) {
        console.error('Failed to fetch ICE servers:', error);
      }
    };

    fetchIceServers();
  }, []);

  const raiseHand = () => {
    socket.emit('messageRoom', {
      type: 'toggle-raise-hand',
      data: { peerId: localPeerIdRef.current },
    });
  };

  // const handleConnect = () => {
  //   console.log('Connected to Socket.IO server');
  //   socket.emit('join', room, Constants.ROLE.STUDENT, (obj) => {
  //     localPeerIdRef.current = obj.socketId;

  //     socket.on('messageRoom', async (obj) => {
  //       console.log('messageRoom', obj);
  //       if (obj.type === 'teacher-joined-the-room') {
  //         setUsersArray((prev) => [...prev, obj.data]);
  //       }
  //       // if (obj.type === 'student_can_join') {
  //       //   console.log('student_can_joinstudent_can_joinstudent_can_join');
  //       //   setCanJoin(true);
  //       // }
  //     });
  //     socket.on('messageDirect', async (obj) => {
  //       if (obj.type === 'webrtc_offer') {
  //         const remotePeerId = obj.data.senderId;
  //         console.log(peerConnectionRef.current[remotePeerId]);
  //         if (
  //           typeof peerConnectionRef.current[remotePeerId] === typeof undefined
  //         ) {
  //           console.log('Creating student RTCPeerConnection');
  //           remotePeerIdsRef.current.push(remotePeerId);
  //           peerConnectionRef.current[remotePeerId] = new RTCPeerConnection(
  //             iceServers,
  //           );
  //           peerConnectionRef.current[remotePeerId].addEventListener(
  //             'datachannel',
  //             (event) => {
  //               console.log(event.channel, 'event.channel');
  //               // const dataChannel = event.channel;
  //               dataChannelRef.current[remotePeerId] = event.channel;
  //               dataChannelRef.current[remotePeerId].onopen = function () {
  //                 console.log('Data channel opened');
  //               };
  //               dataChannelRef.current[remotePeerId].onmessage = function (
  //                 event,
  //               ) {
  //                 const newMessage = JSON.parse(event.data);
  //                 if (newMessage.type === 'group') {
  //                   setGroupMessages((prev) => [...prev, newMessage]);
  //                 } else if (newMessage.type === 'direct') {
  //                   setDirectMessages((prev) => ({
  //                     ...prev,
  //                     [newMessage.sender]: [
  //                       ...(prev[newMessage.sender] || []),
  //                       newMessage,
  //                     ],
  //                   }));
  //                 }
  //                 console.log('Received message:', newMessage);
  //               };
  //               dataChannelRef.current[remotePeerId].onclose = function () {
  //                 console.log('Data channel closed');
  //               };
  //             },
  //           );
  //           // addLocalTracks(peerConnectionRef.current[remotePeerId]);
  //           peerConnectionRef.current[remotePeerId].setRemoteDescription(
  //             new RTCSessionDescription(obj.data.sdp),
  //           );
  //           peerConnectionRef.current[remotePeerId].addEventListener(
  //             'connectionstatechange',
  //             (event) => handleConnectionStateChange(event, remotePeerId),
  //           );
  //           peerConnectionRef.current[remotePeerId].addEventListener(
  //             'icecandidate',
  //             (event) => handleICECandidateEvent(event, remotePeerId),
  //           );
  //           peerConnectionRef.current[remotePeerId].addEventListener(
  //             'track',
  //             (event) => handleTrackEvent(event, remotePeerId),
  //           );
  //           peerConnectionRef.current[remotePeerId].addEventListener(
  //             'negotiationneeded',
  //             (event) => handleNegotiationneeded(event, remotePeerId),
  //           );
  //         } else {
  //           peerConnectionRef.current[remotePeerId].setRemoteDescription(
  //             new RTCSessionDescription(obj.data.sdp),
  //           );
  //         }
  //         await createAnswer(
  //           peerConnectionRef.current[remotePeerId],
  //           remotePeerId,
  //         );
  //       } else if (obj.type === 'webrtc_answer') {
  //         const remotePeerId = obj.data.senderId;
  //         peerConnectionRef.current[remotePeerId].setRemoteDescription(
  //           new RTCSessionDescription(obj.data.sdp),
  //         );
  //       } else if (obj.type === 'webrtc_ice_candidate') {
  //         const remotePeerId = obj.data.senderId;
  //         var candidate = new RTCIceCandidate({
  //           sdpMLineIndex: obj.data.label,
  //           candidate: obj.data.candidate,
  //         });
  //         peerConnectionRef.current[remotePeerId].addIceCandidate(candidate);
  //       }
  //     });
  //     // socket.on('message', (message) => {
  //     //   if (message.type === 'offer') {
  //     //     handleOffer(message);
  //     //   } else if (message.type === 'candidate') {
  //     //     handleCandidate(message);
  //     //   } else if (message.type === 'stop-screen-share') {
  //     //     handleStopScreenShare();
  //     //   }
  //     // });
  //   });
  // };

  const addLocalTracks = (rtcPeerConnection) => {
    if (!hostPeerIdRef.current) return; // No teacher peerId found

    if (screenShareStreamRef.current) {
      screenShareStreamRef.current.getTracks().forEach((track) => {
        peerConnectionRef.current[hostPeerIdRef.current].addTrack(
          track,
          screenShareStreamRef.current,
        );
      });
    }

    // for (let rtcPeerConnection of remotePeerIdsRef.current) {
    //   if (screenShareStreamRef.current) {
    //     screenShareStreamRef.current.getTracks().forEach((track) => {
    //       peerConnectionRef.current[rtcPeerConnection].addTrack(
    //         track,
    //         screenShareStreamRef.current,
    //       );
    //     });
    //   }
    // }
  };

  const setLocalStream = async () => {
    try {
      const stream = await navigator.mediaDevices.getDisplayMedia({
        video: true,
      });
      screenShareStreamRef.current = stream;
      // Show the screen share in the main video container
      if (myVideoContainerRef.current) {
        myVideoContainerRef.current.srcObject = stream;
      }
      setIsScreenSharing(true);
      // socket.emit('messageRoom', {
      //   type: 'student_can_join',
      // });
      addLocalTracks(true);
      // addLocalTracks(true);
      stream.getVideoTracks()[0].onended = async () => {
        await stopScreenShare();
      };
    } catch (error) {
      console.error('Error sharing screen:', error);

      // If the user denies the permission, call the leaveCall method
      if (
        error.name === 'NotAllowedError' ||
        error.name === 'PermissionDeniedError'
      ) {
        leaveCall();
      }
    }
  };

  const handleStopAndLeave = () => {
    setConfirmModal(true)
    // leaveCall();
  };

  const stopScreenShare = async () => {
    screenShareStreamRef.current.getTracks().forEach((track) => {
      track.stop();
      for (let remotePeerId of remotePeerIdsRef.current) {
        const sender = peerConnectionRef.current[remotePeerId]
          .getSenders()
          .find((s) => s.track === track);
        if (sender) {
          peerConnectionRef.current[remotePeerId].removeTrack(sender);
        }
      }
    });
    screenShareStreamRef.current = null;
    setIsScreenSharing(false);
    if (myVideoContainerRef.current) {
      myVideoContainerRef.current.srcObject = null;
    }
    socket.emit('messageRoom', {
      type: 'stopScreenShare',
      data: {
        roomId: room,
        senderId: localPeerIdRef.current,
        roleId: Constants.ROLE.STUDENT,
      },
    });
  };

  const join = () => {
    socket.emit('join', room, Constants.ROLE.STUDENT, (obj) => {
      setIsRoomJoined(true);
      localPeerIdRef.current = obj.socketId;
      socket.emit('messageRoom', {
        type: 'start_call',
        data: {
          roomId: room,
          senderId: localPeerIdRef.current,
        },
      });

      // Trigger screen sharing immediately after joining
      setLocalStream();
    });
  };

  const createAnswer = async (rtcPeerConnection, remotePeerId) => {
    console.log('Creating answer from student');
    let sessionDescription;
    try {
      sessionDescription = await rtcPeerConnection.createAnswer();
      rtcPeerConnection.setLocalDescription(sessionDescription);
      socket.emit('messageDirect', {
        type: 'webrtc_answer',
        data: {
          sdp: sessionDescription,
          roomId: room,
          senderId: localPeerIdRef.current,
          receiverId: remotePeerId,
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const createOffer = async (rtcPeerConnection, remotePeerId) => {
    let sessionDescription;
    try {
      sessionDescription = await rtcPeerConnection.createOffer();
      rtcPeerConnection.setLocalDescription(sessionDescription);
    } catch (error) {
      console.error(error);
    }
    socket.emit('messageDirect', {
      type: 'webrtc_offer',
      data: {
        sdp: sessionDescription,
        roomId: room,
        senderId: localPeerIdRef.current,
        receiverId: remotePeerId,
      },
    });
  };

  const handleICECandidateEvent = (event, remotePeerId) => {
    if (event.candidate) {
      // console.log(
      //   `Sending ICE Candidate from peer ${localPeerIdRef.current} to peer ${remotePeerId}`,
      // );
      // console.log('Local ICE candidate:', event.candidate);
      // socket.emit('message', {
      //   type: 'candidate',
      //   candidate: event.candidate,
      // });
      if (event.candidate.type === 'srflx') {
        console.log('The STUN server is reachable!');
        console.log(`   Your Public IP Address is: ${event.candidate.address}`);
      }

      // If a relay candidate was found, notify that the TURN server works!
      if (event.candidate.type === 'relay') {
        console.log('The TURN server is reachable !');
      }
      socket.emit('messageDirect', {
        type: 'webrtc_ice_candidate',
        data: {
          senderId: localPeerIdRef.current,
          receiverId: remotePeerId,
          roomId: room,
          label: event.candidate.sdpMLineIndex,
          candidate: event.candidate.candidate,
        },
      });
    } else {
      console.log('All ICE candidates have been sent');
    }
  };

  const handleICEGatheringStateChange = () => {
    console.log(
      'ICE gathering state:',
      peerConnectionRef.current.iceGatheringState,
    );
  };

  const handleConnectionStateChange = (event, remotePeerId) => {
    console.log(
      'Connection state:',
      peerConnectionRef.current[remotePeerId].connectionState,
    );
  };

  const handleTrackEvent = (event, remotePeerId) => {
    console.log('Track event:', event);
    // Attach received tracks to the appropriate video element
    if (mainVideoContainerRef.current) {
      mainVideoContainerRef.current.srcObject = event.streams[0];
    }
  };

  const handleNegotiationneeded = (event, remotePeerId) => {
    createOffer(peerConnectionRef.current[remotePeerId], remotePeerId);
  };

  const sendGroupMessage = (msg) => {
    const newMessage = {
      type: 'group',
      id: localPeerIdRef.current,
      msg,
      sender: username,
    };
    for (let remotePeerId of remotePeerIdsRef.current) {
      // dataChannelRef.current[remotePeerId].send(JSON.stringify(newMessage));
      const dataChannel = dataChannelRef.current[remotePeerId];
      if (dataChannel.readyState === 'open') {
        dataChannel.send(JSON.stringify(newMessage));
      } else {
        console.error(`DataChannel for peer ${remotePeerId} is not open.`);
        // Optionally handle reconnection or notify the user here.
      }
    }
    setGroupMessages((prev) => [...prev, newMessage]);
  };

  const sendDirectMessage = (peerId, msg) => {
    const newMessage = {
      type: 'direct',
      id: localPeerIdRef.current,
      msg,
      sender: localPeerIdRef.current,
      receiver: peerId,
      recieverName: username,
    };
    dataChannelRef.current[peerId].send(JSON.stringify(newMessage));
    setDirectMessages((prev) => ({
      ...prev,
      [peerId]: [...(prev[peerId] || []), newMessage],
    }));
  };

  const leaveCall = () => {
    socket.emit('messageRoom', {
      type: 'student-left-the-room',
      data: {
        roomId: room,
        name: username,
        socketId: localPeerIdRef.current,
        senderId: localPeerIdRef.current,
      },
    });

    if (myVideoContainerRef.current) {
      myVideoContainerRef.current.srcObject = null;
    }
    if (mainVideoContainerRef.current) {
      mainVideoContainerRef.current.srcObject = null;
    }
    if (screenShareStreamRef.current) {
      screenShareStreamRef.current.getTracks().forEach((track) => track.stop());
      screenShareStreamRef.current = null;
    }
    remotePeerIdsRef.current.forEach((peerId) => {
      if (peerConnectionRef.current[peerId]) {
        peerConnectionRef.current[peerId].close();
        delete peerConnectionRef.current[peerId];
      }
      if (dataChannelRef.current[peerId]) {
        dataChannelRef.current[peerId].close();
        delete dataChannelRef.current[peerId];
      }
    });
    remotePeerIdsRef.current = [];
    setIsRoomJoined(false);
    navigate('/');
  };

  const handleMessageRoom = async (obj) => {
    console.log('messageRoom received at student end with type: ', obj.type);
    const remotePeerId = obj.data.senderId;
    if (obj.type === 'start_call') {
      remotePeerIdsRef.current.push(remotePeerId);
      peerConnectionRef.current[remotePeerId] = new RTCPeerConnection(
        {iceServers:iceServers},
      );
      dataChannelRef.current[remotePeerId] =
        peerConnectionRef.current[remotePeerId].createDataChannel(
          'myDataChannel',
        );
      dataChannelRef.current[remotePeerId].onopen = function () {
        console.log('Data channel opened');
      };
      dataChannelRef.current[remotePeerId].onmessage = function (event) {
        const newMessage = JSON.parse(event.data);
        if (newMessage.type === 'group') {
          setGroupMessages((prev) => [...prev, newMessage]);
        } else if (newMessage.type === 'direct') {
          // setDirectMessages((prev) => ({
          //   ...prev,
          //   [newMessage.id]: prev[newMessage.id]
          //     ? [...prev[newMessage.id], newMessage]
          //     : [newMessage],
          // }));
          setDirectMessages((prev) => ({
            ...prev,
            [newMessage.sender]: [
              ...(prev[newMessage.sender] || []),
              newMessage,
            ],
          }));
        }
        console.log('Received message:', newMessage);
      };
      dataChannelRef.current[remotePeerId].onclose = function () {
        console.log('Data channel closed');
        let updatedUsersArray = usersArray.filter((e) => {
          return e.socketId !== remotePeerId;
        });
        console.log(updatedUsersArray);
        setUsersArray(updatedUsersArray);
      };
      // addLocalTracks(peerConnectionRef.current[remotePeerId]);
      peerConnectionRef.current[remotePeerId].addEventListener(
        'connectionstatechange',
        (event) => handleConnectionStateChange(event, remotePeerId),
      );
      peerConnectionRef.current[remotePeerId].addEventListener(
        'icecandidate',
        (event) => handleICECandidateEvent(event, remotePeerId),
      );
      peerConnectionRef.current[remotePeerId].addEventListener(
        'icecandidate',
        (event) => handleICEGatheringStateChange(event, remotePeerId),
      );
      // peerConnectionRef.current[remotePeerId].addEventListener(
      //   'track',
      //   (event) => handleTrackEvent(event, remotePeerId),
      // );
      peerConnectionRef.current[remotePeerId].addEventListener(
        'negotiationneeded',
        (event) => handleNegotiationneeded(event, remotePeerId),
      );
      socket.emit('messageDirect', {
        type: 'student-joined-the-room',
        data: {
          id: 1,
          name: username,
          socketId: localPeerIdRef.current,
          senderId: localPeerIdRef.current,
          receiverId: remotePeerId,
        },
      });
      // addLocalTracks(false, remotePeerId);
    } else if (
      obj.type === 'stopScreenShare' &&
      obj.data.roleId === Constants.ROLE.TEACHER
    ) {
      if (mainVideoContainerRef.current) {
        mainVideoContainerRef.current.srcObject = null;
      }
    } else if (obj.type === 'student-left-the-room') {
      const { senderId, socketId } = obj.data;

      // setUsersArray((prev) => {
      //   let filteredArray = prev.filter((e) => {
      //     return e.socketId !== socketId;
      //   });
      //   return filteredArray;

      let updatedUsersArray = usersArray.filter((e) => {
        return e.socketId !== remotePeerId;
      });
      console.log(updatedUsersArray);
      setUsersArray(updatedUsersArray);
    } else if (obj.type === 'teacher-left-the-room') {
      toast.info('Meeting has been ended', {
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      });

      leaveCall();
    }
    // if (obj.type === 'student_can_join') {
    //   console.log('student_can_joinstudent_can_joinstudent_can_join');
    //   setCanJoin(true);
    // }
  };

  const handleMessageDirect = async (obj) => {
    if (obj.type === 'webrtc_offer') {
      const remotePeerId = obj.data.senderId;
      if (typeof peerConnectionRef.current[remotePeerId] === typeof undefined) {
        console.log('Creating student RTCPeerConnection');
        remotePeerIdsRef.current.push(remotePeerId);
        peerConnectionRef.current[remotePeerId] = new RTCPeerConnection(
          {iceServers:iceServers},
        );
        peerConnectionRef.current[remotePeerId].addEventListener(
          'datachannel',
          (event) => {
            console.log(event.channel, 'event.channel');
            // const dataChannel = event.channel;
            dataChannelRef.current[remotePeerId] = event.channel;
            dataChannelRef.current[remotePeerId].onopen = function () {
              console.log('Data channel opened');
            };
            dataChannelRef.current[remotePeerId].onmessage = function (event) {
              const newMessage = JSON.parse(event.data);
              if (newMessage.type === 'group') {
                setGroupMessages((prev) => [...prev, newMessage]);
              } else if (newMessage.type === 'direct') {
                setDirectMessages((prev) => ({
                  ...prev,
                  [newMessage.sender]: [
                    ...(prev[newMessage.sender] || []),
                    newMessage,
                  ],
                }));
                toast.info(`New Message from Teacher`, {
                  position: 'bottom-right',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: 'dark',
                });
              }
              console.log('Received message:', newMessage);
            };
            dataChannelRef.current[remotePeerId].onclose = function () {
              console.log('Data channel closed');
              let updatedUsersArray = usersArray.filter((e) => {
                return e.socketId !== remotePeerId;
              });
              console.log(updatedUsersArray, 'jkjkjkj');
              setUsersArray(updatedUsersArray);
            };
          },
        );
        // addLocalTracks(peerConnectionRef.current[remotePeerId]);
        peerConnectionRef.current[remotePeerId].setRemoteDescription(
          new RTCSessionDescription(obj.data.sdp),
        );
        peerConnectionRef.current[remotePeerId].addEventListener(
          'connectionstatechange',
          (event) => handleConnectionStateChange(event, remotePeerId),
        );
        peerConnectionRef.current[remotePeerId].addEventListener(
          'icecandidate',
          (event) => handleICECandidateEvent(event, remotePeerId),
        );
        peerConnectionRef.current[remotePeerId].addEventListener(
          'track',
          (event) => handleTrackEvent(event, remotePeerId),
        );
        peerConnectionRef.current[remotePeerId].addEventListener(
          'negotiationneeded',
          (event) => handleNegotiationneeded(event, remotePeerId),
        );
        socket.emit('messageDirect', {
          type: 'student-joined-the-room',
          data: {
            id: 1,
            name: username,
            socketId: localPeerIdRef.current,
            senderId: localPeerIdRef.current,
            receiverId: remotePeerId,
          },
        });
      } else {
        peerConnectionRef.current[remotePeerId].setRemoteDescription(
          new RTCSessionDescription(obj.data.sdp),
        );
      }
      await createAnswer(peerConnectionRef.current[remotePeerId], remotePeerId);
    } else if (obj.type === 'webrtc_answer') {
      const remotePeerId = obj.data.senderId;
      peerConnectionRef.current[remotePeerId].setRemoteDescription(
        new RTCSessionDescription(obj.data.sdp),
      );
    } else if (obj.type === 'webrtc_ice_candidate') {
      const remotePeerId = obj.data.senderId;
      var candidate = new RTCIceCandidate({
        sdpMLineIndex: obj.data.label,
        candidate: obj.data.candidate,
      });
      peerConnectionRef.current[remotePeerId].addIceCandidate(candidate);
    } else if (obj.type === 'teacher-joined-the-room') {
      setUsersArray((prev) => [...prev, obj.data]);
      setTeacherArray((prev) => [...prev, obj.data]);
      hostPeerIdRef.current = obj.data.socketId;
    }
  };

  useEffect(() => {
    if(isLoaded){
      console.log('is Loaded api', isLoaded);
      console.log('is iceservers fetch', iceServers);
      socket.connect();
      socket.on('messageRoom', handleMessageRoom);
      socket.on('messageDirect', handleMessageDirect);
  
      return () => {
        // socket.off('connect', handleConnect);
        socket.off('messageRoom', handleMessageRoom);
        socket.off('messageDirect', handleMessageDirect);
        socket.disconnect();
        setUsersArray([]);
      };
    }


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [username,isLoaded,iceServers]);

  useEffect(() => {
    const handlePopState = (event) => {
      event.preventDefault();
      if (isRoomJoined) {
        setConfirmModal(true);
        window.history.pushState(null, null, window.location.pathname);
      } else {
        navigate('/');
      }
    };

    const handleBeforeUnload = (event) => {
      leaveCall();
    };

    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', handlePopState);
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('popstate', handlePopState);
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isRoomJoined, navigate]);

  const handleConfirmLeave = () => {
    leaveCall();
    setConfirmModal(false);
  };

  const handleCloseModal = () => {
    setConfirmModal(false);
  };

  return (
    <div className="flex flex-col w-[100vw]">
      <div className="flex w-full">
        <LeaveCallModal
          isOpen={confirmModal}
          onClose={handleCloseModal}
          onConfirm={handleConfirmLeave}
        />
        <div className="relative w-4/5 h-[100vh]">
          <video
            // className={clsx([
            //   'mainVideoContainer w-full h-full',
            //   styles.mainVideoContainer,
            // ])}
            className="w-full h-full object-fill bg-gray-400"
            ref={mainVideoContainerRef}
            autoPlay
            playsInline
          ></video>

          <div className="absolute bottom-3 left-[35vw] flex flex-wrap gap-2">
            {!isRoomJoined ? (
              <div className="relative flex flex-col gap-1.5 ml-auto group">
                <p className="absolute top-[-60px] transform translate-y-1/2 px-2.5 py-1 bg-blue-400 rounded-md text-center text-white whitespace-nowrap opacity-0 group-hover:opacity-100 transition-opacity border border-white">
                  Click this button to Join Call
                </p>
                <button
                  className=" flex items-center gap-2 px-6 py-4 shadow-md my-2 rounded-md bg-blue-400 border  border-white  hover:bg-blue-300 text-white "
                  onClick={join}
                >
                  <PhoneCall /> <span className="text-nowrap">Join Call</span>
                </button>
              </div>
            ) : (
              <div className="relative flex flex-col gap-1.5 ml-auto group">
                <p className="absolute top-[-60px] transform translate-y-1/2 px-2.5 py-1 bg-red-400 rounded-md text-center text-white whitespace-nowrap opacity-0 group-hover:opacity-100 transition-opacity border border-white ">
                  Click this button to Leave Call
                </p>
                <button
                  className="flex items-center gap-2 px-3 py-2 shadow-md my-2 rounded-md bg-red-400 border border-white  hover:bg-red-500 text-white text-nowrap"
                  onClick={leaveCall}
                >
                  <PhoneOff /> <span className="text-nowrap">Leave Call</span>
                </button>
              </div>
            )}

            {isRoomJoined && (
              <div>
                {!isScreenSharing ? (
                  <div className="relative flex flex-col gap-1.5 ml-auto group">
                    <p className="absolute top-[-60px] transform translate-y-1/2 px-2.5 py-1 bg-blue-400 rounded-md text-center text-white whitespace-nowrap opacity-0 group-hover:opacity-100 transition-opacity border border-white">
                      Click this button to Share screen
                    </p>
                    <button
                      className="flex items-center gap-2 px-3 py-2 shadow-md my-2 rounded-md bg-blue-400 border border-white  hover:bg-blue-500 text-white text-nowrap"
                      onClick={setLocalStream}
                    >
                      <ScreenShare />{' '}
                      <span className="text-nowrap">Share screen</span>
                    </button>
                  </div>
                ) : (
                  <div className="relative flex flex-col gap-1.5 ml-auto group">
                    <p className="absolute top-[-60px] transform translate-y-1/2 px-2.5 py-1 bg-red-400 rounded-md text-center text-white whitespace-nowrap opacity-0 group-hover:opacity-100 transition-opacity border border-white">
                    If you click the 'Stop Share Screen' button, you will leave the call.
                    </p>
                    <button
                      className="flex items-center gap-2 px-3 py-2 shadow-md my-2 rounded-md bg-red-400 border border-white  hover:bg-red-500 text-white text-nowrap"
                      onClick={handleStopAndLeave}
                    >
                      <ScreenShareOff /> <span>Stop Share Screen</span>
                    </button>
                  </div>
                )}
              </div>
            )}
            {isRoomJoined && (
              <div className="flex items-center gap-4">
                <div className="relative flex flex-col gap-1.5 ml-auto group">
                  {/* Hover text */}
                  <p className="absolute top-[-60px] transform translate-y-1/2 px-2.5 py-1 bg-teal-400 rounded-md text-center text-white whitespace-nowrap opacity-0 group-hover:opacity-100 transition-opacity border border-white">
                    Have a question? Raise hand and ask now.
                  </p>
                  <button
                    className="px-3 py-2 ml-auto inline-flex gap-1 items-center bg-teal-400 border border-white rounded-md shadow-sm shadow-black/30 hover:bg-teal-600 text-white"
                    onClick={() => {
                      raiseHand();
                      setDidRaiseHand(!didRaiseHand);
                    }}
                  >
                    {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                className="size-[2.5ch]"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M10.05 4.575a1.575 1.575 0 1 0-3.15 0v3m3.15-3v-1.5a1.575 1.575 0 0 1 3.15 0v1.5m-3.15 0 .075 5.925m3.075.75V4.575m0 0a1.575 1.575 0 0 1 3.15 0V15M6.9 7.575a1.575 1.575 0 1 0-3.15 0v8.175a6.75 6.75 0 0 0 6.75 6.75h2.018a5.25 5.25 0 0 0 3.712-1.538l1.732-1.732a5.25 5.25 0 0 0 1.538-3.712l.003-2.024a.668.668 0 0 1 .198-.471 1.575 1.575 0 1 0-2.228-2.228 3.818 3.818 0 0 0-1.12 2.687M6.9 7.575V12m6.27 4.318A4.49 4.49 0 0 1 16.35 15m.002 0h-.002"
                />
              </svg> */}
                    <Hand />
                    <span>{didRaiseHand ? 'Lower Hand' : 'Raise Hand'}</span>
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* <div
          className={clsx([
            'myVideoContainerRef ml-4',
            didRaiseHand ? styles.raiseHand : '',
            styles.myVideoContainerRef,
          ])}
        > */}
        {/* <video
            className="w-full h-full object-cover object-center"
            ref={myVideoContainerRef}
            autoPlay
            playsInline
          ></video> */}
        {/* </div> */}
      </div>
      {/* {isRoomJoined && (
        <div className="flex w-3/4 my-4">
          <div className="relative flex flex-col gap-1.5 ml-auto group">
           
            <p className="absolute top-[-50px] transform -translate-x-1/2 px-2.5 py-1 bg-gray-400 rounded-md text-center text-gray-800 whitespace-nowrap opacity-0 group-hover:opacity-100 transition-opacity">
              Have a question? Raise hand and ask now.
            </p>
            <button
              className="p-1.5 ml-auto inline-flex gap-1 items-center bg-teal-700 rounded-md shadow-md shadow-black/30 hover:bg-teal-600 text-white"
              onClick={() => {
                raiseHand();
                setDidRaiseHand(!didRaiseHand);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                className="size-[2.5ch]"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M10.05 4.575a1.575 1.575 0 1 0-3.15 0v3m3.15-3v-1.5a1.575 1.575 0 0 1 3.15 0v1.5m-3.15 0 .075 5.925m3.075.75V4.575m0 0a1.575 1.575 0 0 1 3.15 0V15M6.9 7.575a1.575 1.575 0 1 0-3.15 0v8.175a6.75 6.75 0 0 0 6.75 6.75h2.018a5.25 5.25 0 0 0 3.712-1.538l1.732-1.732a5.25 5.25 0 0 0 1.538-3.712l.003-2.024a.668.668 0 0 1 .198-.471 1.575 1.575 0 1 0-2.228-2.228 3.818 3.818 0 0 0-1.12 2.687M6.9 7.575V12m6.27 4.318A4.49 4.49 0 0 1 16.35 15m.002 0h-.002"
                />
              </svg>
              <span>{didRaiseHand ? 'Lower Hand' : 'Raise Hand'}</span>
            </button>
          </div>
        </div>
      )} */}
      <Chat
        localPeerId={localPeerIdRef.current}
        usersArray={teacherArray}
        groupMessages={groupMessages}
        directMessages={directMessages}
        sendGroupMessage={sendGroupMessage}
        sendDirectMessage={sendDirectMessage}
        teacherView={false}
        isRoomJoined={isRoomJoined}
      />
    </div>
  );
}

export default StudentRoom;
