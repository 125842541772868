import React from 'react';
import Loginform from './form/loginform';

export default function LogIn() {
  return (
    <div className="bg-cyan-400 min-h-screen w-full">
      <div className="  container relative grid h-svh  flex-col items-center justify-center lg:max-w-full lg:grid-cols-2 lg:px-0 ">
        <div className="lg:p-8">
          <div className="mx-auto my-3 flex w-full flex-col justify-center space-y-2 sm:w-[350px] border bordder-2 bg-white border-black rounded-md shadow-md">
            <div className="flex flex-col mb-1 mt-2 text-left p-3">
              <h1 className="text-2xl font-semibold tracking-tight">Login</h1>
              <p className="text-sm text-muted-foreground">
                Enter your email and password below <br />
                to log into your account
              </p>
            </div>
            <Loginform />

            <p className="px-3 pb-1 text-start text-sm text-muted-foreground text-gray-400">
              Don’t have a account ?{' '}
              <a
                href="/signup"
                className="underline underline-offset-4 hover:text-primary"
              >
                <b>SignUp Here</b>
              </a>{' '}
            </p>
            <p className="px-3 pb-3 text-start text-sm text-muted-foreground text-gray-400">
              By clicking login, you agree to our{' '}
              <a
                href="/terms"
                className="underline underline-offset-4 hover:text-primary"
              >
                Terms of Service
              </a>{' '}
              and{' '}
              <a
                href="/privacy"
                className="underline underline-offset-4 hover:text-primary"
              >
                Privacy Policy
              </a>
              .
            </p>
          </div>
        </div>

        <div className="relative hidden h-full flex-col bg-muted px-0 py-10 text-white dark:border-r lg:flex">
          <div className="absolute inset-0 " />
          <div className="relative flex items-start justify-start ">
            <p className="text-white text-5xl font-extrabold leading-6 font-outline-2 -ml-24 -mt-3">
              EDFX
            </p>
          </div>

          <img
            src="/images/navbar/formimage.png"
            className="relative m-auto"
            width={400}
            height={80}
            alt="EDFX"
          />

          <div className="relative px-0 mt-auto text-nowrap -ml-72">
            <blockquote className="space-y-2">
              <p className="italic text-white text-lg font-medium">
                &ldquo;From Aspiration to Achievement: The Premier Platform for
                Online Education&rdquo;
              </p>
            </blockquote>
          </div>
        </div>
      </div>
    </div>
  );
}
